import * as React from "react";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import _ from 'lodash';

export default function UserComboBox(props) {
    const { values, handleChange, label, disabled, value, width } = props;
    const [textboxValue, setTextboxValue] = React.useState('');
    return (
        <Autocomplete
            id="CustomCombobox"
            disabled={disabled}
            options={values}
            getOptionLabel={(item) => item.id}
            onChange={(e, value) => {
                setTextboxValue(value.id);
                handleChange(value);
            }}
            value={value}
            defaultValue={value}
            sx={{ minWidth: width }}
            loading={values.length === 0}
            renderInput={(params) => (
                <TextField
                    {...params}
                    sx={{ minHeight: '1.4375em', height: 'auto' }}
                    onChange={(e) => {
                        const fieldValue = e.target.value;
                        if (isNaN(fieldValue)) {
                            e.preventDefault();
                        } else
                        {
                            setTextboxValue(fieldValue);
                        }
                    }}
                    value={textboxValue}
                    label={label}
                    size="small"
                />
            )}
        />
    );
}
