import React, { useState} from 'react';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import TableHeader from './SortTable/TableHeader';
import TableContent from './SortTable/TableContent';
import _ from 'lodash';
import { GetComparator, StableSort } from '../../Utils/SortUtil';

export function TableInfo(props) {
    const [orderDirection, setOrderDirection] = useState('asc');
    const [valueToOrderBy, setValueToOrderBy] = useState(_.keys(props.info[0])[0]);

    const handleRequestSort = (event, property) => {
        const isAscending = (valueToOrderBy === property && orderDirection === 'asc');
        setValueToOrderBy(property);
        setOrderDirection(isAscending ? 'desc' : 'asc')
    }
    const { info } = props;
    return (
         <Paper sx={{ overflow: 'hidden' }}>
              <TableContainer sx={{ maxHeight: 440 }}>
                  <Table stickyHeader aria-label="sticky table">
                      <TableHeader
                          valueToOrderBy={valueToOrderBy}
                          orderDirection={orderDirection}
                          handleRequestSort={handleRequestSort}
                          keys={_.keys(info[0])}
                          columnColl={['Name', 'Description']}
                      />
                      <TableBody>
                      {
                            StableSort(info, GetComparator(orderDirection, valueToOrderBy))
                                .map(({ key, value }, index) => {
                              let bgColor = "white";
                              let color = "black";
                              if (key == "Duplicate") {
                                  bgColor = "#f44336"; //red
                                  color = "white";
                              }
                              return (
                                <TableContent
                                    key={index}
                                    index={index}
                                      primaryColor={bgColor}
                                      backgroundColor={bgColor}
                                    items={[ key, value ]}
                                    txtColor={color}
                                />
                              )
                          })
                      }
                      </TableBody>
                  </Table>
              </TableContainer>
          </Paper>
    ); 
}
