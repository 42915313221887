import React, { useState } from 'react';
import { Button, Stack, Typography } from '@mui/material';

import { DialogConfirm } from './DialogConfirm';
import { LoadingWindow } from '../common/LoadingWindow';
import _ from 'lodash';

export const ButtonWriteToDB = (props) => {
    const [isOpen, setIsOpen] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    const toggle = () => { 
        setIsOpen(true) ;
    }

    const { 
        fileName, 
        isEpodDBChecked, 
        isLocalDBChecked, 
        warningState, 
        isHoursIdentical, 
        hasRunsToIgnore,
        isCheckerExists,
        clearFileName,
        disabledWrite,
        criticalWarningText,
        isRotary,
        isTimesExist,
        isMdbExists,
        isCriticalError,
        runsToIgnore,
        isMergeDisabled,
        equipment
    } = props;

    const disabled = _.isNil(fileName) || fileName === '' || !isCheckerExists
        || !isMdbExists || isCriticalError || isMergeDisabled;

    const requestOptions = {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ fileName, isEpodDBChecked, isLocalDBChecked, equipment }),
    };

    console.log(equipment);

    return (
        <div>
            <Stack>
                <Button
                    disabled={disabled}
                    color="error"
                    variant="outlined"
                    onClick={toggle}
                >
                    Write to database
                </Button>
                {isCriticalError ?
                    <Typography color='error'>
                        Please check critical statuses
                    </Typography>
                    : ''}
            </Stack>
            <DialogConfirm
                title="WARNING!"
                contentText="Upload a file to the database?"
                successMessage="Success write to db"
                warningState={warningState}
                failMessage="Failed write to db"
                fetchMethod={() => {
                    return fetch("/db/UploadToDb", requestOptions);
                }}
                onApply={() => {
                    clearFileName();
                }}
                setIsOpen={setIsOpen}
                isOpen={isOpen}
                setIsLoading={setIsLoading}
                fileName={fileName}
                isLocalDBChecked={isLocalDBChecked}
                isEpodDBChecked={isEpodDBChecked}
                okBtnText="Upload to DB"
                okTip="Delete old values and write a new ones"
                closeBtnText="Cancel"
                closeTip="Cancel"
                disabledWrite={disabledWrite}
                hasRunsToIgnore={hasRunsToIgnore}
                criticalWarningText={criticalWarningText}
                isHoursIdentical={isHoursIdentical}
                isRotary={isRotary}
                isTimesExist={isTimesExist}
                runsToIgnore={runsToIgnore}
            />
            <LoadingWindow isLoading={isLoading} loadDesc={"writing to the database..."} />
        </div>
    );
}
