import React, { useState, useEffect } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { AlertNotification } from '../common/AlertNotification';
import _ from 'lodash';
import { LightTooltip } from './../Utils/LightTooltip';


function concatText(textSource, otherText) {
    if (textSource === "") {
        return otherText;
    }
    return _.concat(textSource, " ", otherText).toString();
}

export function DialogConfirm({
    isOpen,
    title,
    contentText,
    closeBtnText = "Disagree",
    okBtnText = "Agree",
    successMessage,
    failMessage,
    setIsOpen,
    setIsLoading,
    onApply,
    fetchMethod,
    isAltVariant,
    altText,
    hasRunsToIgnore,
    disabledWrite,
    criticalWarningText,
    isRotary,
    isEpodDBChecked,
    isLocalDBChecked,
    closeTip,
    okTip, 
    runsToIgnore,
    isHoursIdentical,
}) {

    const [showAlert, setShowAlert] = useState(false);
    const [alertMessage, setAlertMessage] = useState(failMessage);
    const [alertState, setAlertState] = useState("error");
    const [ignoreEquals, setIgnoreEquals] = useState(false);

    console.log(ignoreEquals);

    const handleClose = () => {
        setIsOpen(false);
    };

    const processWriteResponse = (res) => {
        if (res.ok) {
            setIsLoading(false);
            setAlertMessage(successMessage);
            setAlertState("success");
        } else {
            const error = res.json();
            error.then((ex) => {
                setAlertMessage(`${failMessage}: ${ex.error}`);
                setIsLoading(false);
                setAlertState("error");
            });
        }
    }

    const agreeHandleClose = (fetchMethod) => {
        setIsOpen(false);
        setIsLoading(true);

        if (fetchMethod) {
            fetchMethod()
                .then((res) => {
                    processWriteResponse(res);
                    onApply();
                    setShowAlert(true);
                });
        }
    };

    const handleAgreeOpenWarning = (btnText, fetchMethod) => {
        agreeHandleClose(fetchMethod);
    }

    let text = "";

    function WriteDialog({
        isAltVariant
    }) {
        let buttonsGroup;
        if (hasRunsToIgnore) {
            text = concatText(text, runsToIgnore);
        };
        if (disabledWrite && isRotary)
        {
                text = concatText(text, "The BHA is Rotary type, please be sure " +
                    "that BRT and ART time inputted correctly.\n");
                if (isAltVariant && !ignoreEquals) {
                    text = concatText(text, altText);
                    buttonsGroup = [
                        [[okBtnText, okTip], handleAgreeOpenWarning, fetchMethod],
                        [[closeBtnText, closeTip], handleClose]
                    ];
                } else
                {
                    buttonsGroup = [
                        [[okBtnText, okTip], handleAgreeOpenWarning, fetchMethod],
                        [[closeBtnText, closeTip], handleClose]
                    ];
                }
        }
        else if (isAltVariant)
        {
            if (ignoreEquals) {
                text = concatText(text, contentText);
                buttonsGroup = [
                    [[okBtnText, okTip], handleAgreeOpenWarning, fetchMethod],
                    [[closeBtnText, closeTip], handleClose]
                ];
            } else
            {
                text = concatText(text, altText);
                buttonsGroup = [
                    [[okBtnText, okTip],  handleAgreeOpenWarning, fetchMethod],
                    [[closeBtnText, closeTip], handleClose]
                ];
            }
        }
        else
        {
            text = concatText(text, contentText);
            buttonsGroup = [
                [[okBtnText, okTip], handleAgreeOpenWarning, fetchMethod], 
                [[closeBtnText, closeTip], handleClose]
            ];
        }

        return (
            <Dialog
                open={isOpen}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description">
                <DialogTitle id="alert-dialog-title">
                    {title}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        {
                            text.split(';').map(line =>
                            {
                                return (<>{line.replaceAll(",", "").trimStart()} < br /></>);
                            })
                        }
                        {isHoursIdentical ? "Old hours are identical to new ones." : ""}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    {buttonsGroup.map((group, index) =>
                    {
                        const buttonTexts = group[0];
                        const onClick = group[1];
                        const onClickFunction = group.length > 2 ? () => onClick(buttonTexts[0], group[2]) : onClick;
                        return (
                            <LightTooltip title={buttonTexts[1]}>
                                <Button onClick={onClickFunction} key={"dialog-button-" + index}>
                                    {buttonTexts[0]}
                                </Button>
                            </LightTooltip>);
                    })}
                </DialogActions>
            </Dialog>
        );
    }

    useEffect(() =>
    {
        if ((isEpodDBChecked && !isLocalDBChecked) != null && (isEpodDBChecked && !isLocalDBChecked) != ignoreEquals)
        {
            setIgnoreEquals(isEpodDBChecked && !isLocalDBChecked);
        }
    })

    return (
        <div>
            <WriteDialog 
                isAltVariant={isAltVariant} />
            <AlertNotification
                alertState={alertState}
                messageInfo={alertMessage}
                setIsOpen={setShowAlert.bind(this)}
                isOpen={showAlert}
            />
        </div>
    );
}