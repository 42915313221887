import React, { Component } from 'react';
import { Container, Input } from 'reactstrap';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import { ButtonWriteToDB } from './ButtonWriteToDB';
import { ButtonCheck } from './ButtonCheck';
import { Button, Typography } from '@mui/material';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import { ButtonRemoveFromDB } from './ButtonRemoveFromDB';


function CheckboxFormControl(props) {
    return (
        <FormControlLabel control={<Checkbox checked={props.checked} />} name={props.name} onChange={props.handleChange}
            label={props.label} />
    );
}

export class Merge extends Component {
  static displayName = Merge.name;
    constructor(props) {
        super(props);

        this.state = {
            currentFileName: "",
            onlyFileName: "",
            isEpodDBChecked: true,
            isLocalDBChecked: true,
            isCheckerExists: false,
            isReadTimeLaterRotary: false,
            isHoursIdentical: false,
            hasRunsToIgnore: false,
            disabledWrite: false,
            criticalWarningText: '',
            isTimesExist: false,
            isRotary: false,
            isMdbExists: false,
            isCriticalError: false,
            runsToIgnore: "",
            isMergeDisabled: false,
            equipmentsToWriteView: []
        }
    }

    fetchUpload(requestOptions) {
        fetch("/file/UploadFile", requestOptions)
            .then((res) => {
                return res.json()
            })
            .then((res) => {
                console.log("check file upload post json", res);
                this.setState({
                    currentFileName: res.fileName,
                    onlyFileName: res.onlyFileName
                })
            });
    }

    handleSubmit() {
        const input = document.getElementById("fileUploader");
        const files = input["files"];
        let formData = new FormData();

        for (var file of files) {
            formData.append("files", file, file.name);
        }

        const requestOptions = {
            method: "POST",
            body: formData,
        };

        console.log("check merge file", requestOptions, files);

        this.fetchUpload(requestOptions)
    }

    clearFileName() {
        this.setState({
            currentFileName: '', onlyFileName: '', isReadTimeLaterRotary: false, isCheckerExists: false,
            disabledWrite: false, criticalWarningText: '', isRotary: false,
            isTimesExist: false, isMdbExists: false, isCriticalError: false, runsToIgnore: ""
        });
        const input = document.getElementById("fileUploader");
        input.value = '';
    }

    handleChange = ({ target: { name, checked } }) => { this.setState({ [name]: checked }); };
    toggleMerge = () => { fetch(`file/ToggleMerge`).then((res) => res.json()).then((res) => this.setState({isMergeDisabled: res})) };

    setWarning(value)
    {
        this.setState({ isReadTimeLaterRotary: value });
    }

    setDisabled(value) {
        this.setState({ disabledWrite: value });
    }

    setExtendedWarning(value)
    {
        this.setState({ isHoursIdentical: value });
    }

    setHasRunsToIgnore(value) {
        this.setState({ hasRunsToIgnore: value });
    }

    setIsCheckerExists()
    {
        this.clearFileName();
    }

    setCriticalWarningText(value)
    {
        this.setState({ criticalWarningText: value });
    }

    setIsRotary(value)
    {
        this.setState({ isRotary: value });
    }

    setIsTimesExist(value) {
        this.setState({ isTimesExist: value });
    }

    setIsMdbExists(value)
    {
        this.setState({ isMdbExists: value });
    }

    setIsCritical(value) {
        this.setState({ isCriticalError: value });
    }

    setRunsToIgnore(value)
    {
        this.setState({ runsToIgnore: value });
    }

    setEquipmentsToWriteView(value)
    {
        this.setState({ equipmentsToWriteView: value });
    }

    componentDidMount() {
        fetch(`file/IsMergeDisabled`).then((res) => res.json()).then((res) => this.setState({ isMergeDisabled: res }));
    }

    render() {
        const { currentFileName, onlyFileName, isEpodDBChecked, isLocalDBChecked,
            isCheckerExists, isReadTimeLaterRotary, isHoursIdentical, hasRunsToIgnore,
            disabledWrite, criticalWarningText, isRotary,
            isTimesExist, isMdbExists, isCriticalError, runsToIgnore, isMergeDisabled, equipmentsToWriteView } = this.state;
        const { job, run, role } = this.props;

        console.log(role);

        return (
            <Container fluid>
                <Box>
                    <Typography variant="h7">
                        Current File: {onlyFileName || "No file"}
                    </Typography>
                    <Stack direction="row" spacing={2} alignItems="center">
                        <CheckboxFormControl label="Include EPOD DB" name='isEpodDBChecked' checked={isEpodDBChecked} handleChange={this.handleChange.bind(this)} />
                        <CheckboxFormControl label="Include 1C ERP DB" name='isLocalDBChecked' checked={isLocalDBChecked} handleChange={this.handleChange.bind(this)} />
                        {role === 'Admin' ? <CheckboxFormControl label="Disable merge" name='isMergeDisabled' checked={isMergeDisabled} handleChange={this.toggleMerge.bind(this)} /> : <></> }
                    </Stack>
                    <Stack direction="row" spacing={2}>
                        <Box component="form">
                            <Button
                                color="error"
                                variant="outlined"
                                style={{ marginBottom: '1rem' }}
                                onClick={() => { this.setState({ isCheckerExists: false, criticalWarningText: '' }); }}
                                component="label">
                                Upload InSite case
                                <Input
                                    hidden
                                    accept=".zip,.rar,.7z"
                                    type="file"
                                    id="fileUploader"
                                    onChange={this.handleSubmit.bind(this)}
                                    onClick={this.setIsCheckerExists.bind(this)}
                                />
                            </Button>
                        </Box>
                        {role !== 'Field' ?                         
                            <>
                                <ButtonWriteToDB
                                    fileName={currentFileName}
                                    clearFileName={this.clearFileName.bind(this)}
                                    isLocalDBChecked={isLocalDBChecked}
                                    isEpodDBChecked={isEpodDBChecked}
                                    isCheckerExists={isCheckerExists}
                                    warningState={isReadTimeLaterRotary}
                                    isHoursIdentical={isHoursIdentical}
                                    hasRunsToIgnore={hasRunsToIgnore}
                                    disabledWrite={disabledWrite}
                                    criticalWarningText={criticalWarningText}
                                    isRotary={isRotary}
                                    isTimesExist={isTimesExist}
                                    isMdbExists={isMdbExists}
                                    isCriticalError={isCriticalError}
                                    runsToIgnore={runsToIgnore}
                                    isMergeDisabled={isMergeDisabled}
                                    equipment={equipmentsToWriteView}
                                />
                                <ButtonRemoveFromDB
                                    fileName={currentFileName}
                                    job={job}
                                    run={run}
                                    clearFileName={this.clearFileName.bind(this)}
                                    isLocalDBChecked={isLocalDBChecked}
                                    isEpodDBChecked={isEpodDBChecked}                          
                                />
                            </> :
                            <></>}
                    </Stack>
                    <ButtonCheck fileName={currentFileName} onClick={() => { this.setState({ isCheckerExists: true }); }}
                        setWarning={this.setWarning.bind(this)} warningState={isReadTimeLaterRotary}
                        setExtendedWarning={this.setExtendedWarning.bind(this)} isHoursIdentical={isHoursIdentical}
                        setHasRunsToIgnore={this.setHasRunsToIgnore.bind(this)} hasRunsToIgnore={hasRunsToIgnore}
                        disabledWrite={disabledWrite} setDisabled={this.setDisabled.bind(this)}
                        criticalWarningText={criticalWarningText} setCriticalWarningText={this.setCriticalWarningText.bind(this)}
                        setIsRotary={this.setIsRotary.bind(this)} setIsTimesExist={this.setIsTimesExist.bind(this)}
                        isRotary={isRotary} isTimesExist={isTimesExist} isCheckerExists={isCheckerExists}
                        setIsMdbExists={this.setIsMdbExists.bind(this)} setIsCritical={this.setIsCritical.bind(this)}
                        setRunsToIgnore={this.setRunsToIgnore.bind(this)} equipmentsToWriteView={equipmentsToWriteView}
                        setEquipmentsToWriteView={this.setEquipmentsToWriteView.bind(this)}
                    />
                </Box>
            </Container>
        ); 
    }
}
