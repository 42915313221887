import React from 'react';
import Box from '@mui/material/Box';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';

import { Link } from 'react-router-dom';

export function RunsList({
    item,
    name,
    run,
    setSelectRun,
    handleOpen,
    job
}) {

    return (
        <Box sx={{
            width: '100%',
            height: '40vh',
        }}>
            <Typography variant="h6" component="div">
                {name}
            </Typography>
            <Paper elevation={1}>
                <List sx={{ overflow: 'auto', height: '35vh' }} >
                    {item.map((item, index) =>
                    {
                        const isSelected = run === item;
                        const bgColor = isSelected ? '#ffcc1c' : 'white';
                        const txtColor =  'black';
                        return (
                            <Link key={index} to='/' style={{ textDecoration: 'none' }}>
                                <ListItemButton sx={{
                                    background: bgColor, "&:hover":
                                    {
                                        background: '#ffdd1d'
                                    }
                                }}
                                    key={index} onClick={() => {
                                        setSelectRun(item);
                                        handleOpen(item, job);
                                    }}
                                >
                                    <ListItemText sx={{
                                        color: txtColor
                                    }}> {item} </ListItemText>
                                </ListItemButton>
                            </Link>
                        );
                    })}
                </List>
            </Paper>
        </Box>
    );
}