import React, { useEffect, useState } from 'react';
import Button from '@mui/material/Button';
import Collapse from '@mui/material/Collapse';
import { ButtonSend } from './ButtonSend';
import Tooltip from '@mui/material/Tooltip';
import { LoadingWindow } from '../common/LoadingWindow';
import _ from 'lodash';
import { TableList } from './Tables/TableList';

export function ButtonCheck(props) {
    const [isOpen, setIsOpen] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [info, setInfo] = useState([]);
    const [statuses, setStatuses] = useState([]);
    const [equipments, setEquipments] = useState([]);
    const [runList, setRunList] = useState([]);
    const [runListInDB, setRunListInDB] = useState([]);
    const [criticalStatuses, setCriticalStatuses] = useState([]);
    const { fileName, setWarning, warningState,
        disabledWrite, setDisabled, criticalWarningText, setCriticalWarningText,
        setIsRotary, setIsTimesExist, isRotary, isTimesExist,
        isCheckerExists, setIsMdbExists, setIsCritical, setRunsToIgnore, setEquipmentsToWriteView, equipmentsToWriteView } = props;

    function getDups(array) {
        const equipsDups = _.filter(array, v => {
            return _.filter(array, u => u.item1 === v.item1).length > 1;
        });
        return equipsDups;
    }


    useEffect(() => {
        setIsOpen(false);
    }, [fileName]);

    const fetchCheck = (requestOptions) =>
    {
        setStatuses([]);
        setCriticalStatuses([]);
        fetch("file/GetCheck", requestOptions)
            .then((res) => {
                return res.json()
            })
            .then((res) => {
                console.log("get check json", res);
                setInfo(res.info);
                setStatuses(res.statuses);
                setEquipments(res.equipments);
                setIsLoading(false);
                setEquipmentsToWriteView(res.equimpentsToWriteView);
                setRunList(res.runList);
                setRunListInDB(res.runListInDB)
                props.setExtendedWarning(res.isHoursIdentical);
                props.setHasRunsToIgnore(res.hasRunsToIgnore);
                props.setIsMdbExists(res.isMdbExists);
                setIsCritical(res.isCriticalError);
                setCriticalStatuses(res.criticalStatuses);
                setRunsToIgnore(res.runsToIgnore);
            })
    }

    const toggle = () => {
        setIsOpen(!isOpen);
        if (!isOpen) {
            setIsLoading(true);
            const requestOptions = {
                method: "POST",
                headers: { "Content-Type": "application/json" },
                body: JSON.stringify(fileName),
            };
            fetchCheck(requestOptions);
        }
    }

    return (
        <div>
            <div style={{ display: 'flex', justifyContent: 'space-between'}}>
                <Tooltip title="Send file to validation check">
                    <Button
                        color="error"
                        variant="outlined"
                        onClick={() => { toggle(); props.onClick(); }}
                        style={{ marginBottom: '1rem', width: '10vw' }}
                        disabled={isLoading}
                    >
                        Check
                    </Button>
                </Tooltip>
                <ButtonSend fileName={props.fileName} />
            </div>
            <LoadingWindow isLoading={isLoading} loadDesc={"checking data..."} />
            <Collapse in={isOpen}>
                <TableList
                    isLoading={isLoading}
                    info={info}
                    statuses={statuses}
                    equipments={_.uniqBy(equipments, 'item1')}
                    runList={runList}
                    runListInDB={runListInDB}
                    dups={getDups(equipments)}
                    equimpentsToWriteView={equipmentsToWriteView}
                    setWarning={setWarning}
                    warningState={warningState}
                    setDisabled={setDisabled}
                    disabledWrite={disabledWrite}
                    criticalWarningText={criticalWarningText}
                    setCriticalWarningText={setCriticalWarningText}
                    isTimesExist={isTimesExist}
                    setIsTimesExist={setIsTimesExist}
                    isRotary={isRotary}
                    setIsRotary={setIsRotary}
                    isCheckerExists={isCheckerExists}
                    criticalStatuses={criticalStatuses}
                    setStatuses={setStatuses}
                    setCriticalStatuses={setCriticalStatuses}
                />
            </Collapse>
        </div>
    );
}