import React, { useState } from 'react';
import Button from '@mui/material/Button';

import { DialogConfirm } from './DialogConfirm';
import { LoadingWindow } from '../common/LoadingWindow';
import _ from 'lodash';

function checkWrittenToLastFile(setHasWritten) {
    fetch("/db/CheckWrittenToLastFile")
        .then(res => {
            if (res.ok) {
                return res.json();
            }
        })
        .then(res => {
            setHasWritten(res);
        });
}

export const ButtonRemoveFromDB = (props) => {
    const [isOpen, setIsOpen] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [hasWritten, setHasWritten] = useState(false);

    const toggle = () => { 
        setIsOpen(true);
        checkWrittenToLastFile(setHasWritten);
    }

    const { fileName, isEpodDBChecked, isLocalDBChecked, job, run } = props;

    const disabled = _.isNil(job) || job === '' || _.isNil(run) || run === '';

    const title = `Remove selected job: ${job} and run: ${run} from db`;

    const requestOptions = {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ isEpodDBChecked, isLocalDBChecked, job, run }),
    };

    return (
        <div>
            <Button
                disabled={disabled}
                color="error"
                variant="outlined"
                onClick={toggle}
                style={{ marginBottom: '1rem'}}
            >
                Remove from database
            </Button>
            <DialogConfirm
                title={title}
                contentText="Remove from the database?"
                successMessage="Success write to db"
                failMessage="Failed write to db"
                fetchMethod={() => {
                    return fetch("/db/RemoveFromDb", requestOptions);
                }}
                onApply={() => { props.clearFileName(); }}
                setIsOpen={setIsOpen}
                isOpen={isOpen}
                setIsLoading={setIsLoading}
                okBtnText="Remove"
                okTip="Remove job and CBM hours from DB."
                closeBtnText="Cancel"
                closeTip="Cancel"
                fileName={fileName}
                isLocalDBChecked={isLocalDBChecked}
                isEpodDBChecked={isEpodDBChecked}
                isAltVariant={hasWritten}
                altButtonText="Remove without 1C charging."
                altTip="Remove job without 1C charging from DB."
                altText="Hours for the current 1C file was not charged."
                altFetchMethod={() => {
                    return fetch("/db/RemoveWihtoutOneC", requestOptions);
                }}
            />
            <LoadingWindow isLoading={isLoading} loadDesc={"removing from the database..."} />
        </div>
    );
}
