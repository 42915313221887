import React, { useState } from 'react';
import { Input } from 'reactstrap';
import { Button, Box, Typography, Stack, } from '@mui/material';

function clearFileUploader() {
    const input = document.getElementById("fileUploader");
    input.value = '';
}

export function ButtonImport({
        setIsLoading, 
        setLoadingDescription,
        setIsOpen,
        setAlertState,
        setMessageInfo,
        setIsReloadFileHistory,
        setFileName,
        setIsIgnoreDialogOpen,
        setIgnoreDialogText,
        setForceIgnoreText,
        setLoopedDialogText
    }) {

    const processImportResponse = (res) =>
    {
        if (res.ok) {
            setAlertState("success");
            setMessageInfo("The file has been submitted");
            setIsReloadFileHistory(true);
            return res.json();
        }
        else {
            setAlertState("error");
            const error = res.json();
            error.then((ex) => {
                setMessageInfo(`Failed to submit file: ${ex.error}`);
            });
        }
    }

    const fetchSumbit = (requestOptions) =>
    {
        fetch("/erp/Import1CERP", requestOptions)
            .then((res) => {
                setIsLoading(false);
                setIsOpen(true);
                clearFileUploader();
                console.log("file upload", res);
                return processImportResponse(res);
            })
            .then((res) => {
                console.log("file uploaded", res);
                setFileName(res.fileName);
                if (res.equipmentIgnoreString != '' || res.forceIgnoreString != '')
                {
                    setIgnoreDialogText(res.equipmentIgnoreString);
                    setForceIgnoreText(res.forceIgnoreString);
                    setLoopedDialogText(res.loopedString);
                    setIsIgnoreDialogOpen(true);
                }
            });
    }

    const handleSubmit = () =>
    {
        const input = document.getElementById("fileUploader");
        const files = input["files"];
        let formData = new FormData();
        for (var file of files) {
            formData.append("files", file, file.name);
        }

        setIsLoading(true);
        setLoadingDescription("Submitting 1C ERP file");

        const requestOptions =
        {
            method: "POST",
            body: formData,
        };

        fetchSumbit(requestOptions);
        
    }

    return (
        <Box component="form">
            <Button
                color="error"
                variant="outlined"
                style={{ marginBottom: '1rem' }}
                component="label">
                    Import from 1C ERP
                    <Input
                        hidden
                        accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                        type="file"
                        id="fileUploader"
                        onChange={handleSubmit}
                    />
            </Button>
        </Box>
    );
}