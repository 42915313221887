import React from 'react';
import Box from '@mui/material/Box';
import { TableInfo } from './../merge/Tables/TableInfo'
import { TableEquipment } from './../merge/Tables/TableEquipment';
import { TableStatuses } from './../merge/Tables/TableStatuses';
import { EquipmentCmbWrapper } from './../merge/Tables/EquipmentCbmTable';
import { Accordion, AccordionDetails, AccordionSummary, Typography } from '@mui/material';
import _ from 'lodash';

const TableWrapper = (function TableWrapper(props) {
    return (
        <Box mb={0.5} {...props} />
    );
});

const TableAccordion = (function TableAccordion(props)
{
    return (
        <Accordion expanded={props.expanded}>
            <AccordionSummary sx={{ background: props.bgColor }}>
                <Typography sx={{ color: props.txtColor }}>
                    {props.name}
                </Typography>
            </AccordionSummary>
            <AccordionDetails>{props.children}</AccordionDetails>
        </Accordion>
    );
})

export function HomeTableList(props) {
    const bgColor = "#d32f2f";
    const txtColor = "white";
    const { selectedJob, selectedRun, info, statuses, setIsRotary, isRotary,
                criticalStatuses, equipments, equimpentsToWriteView} = props;

    return (
        <Box sx={{ width: '100%' }}>
            <TableWrapper>
                <TableAccordion
                    expanded={selectedJob !== '' && selectedRun != ''}
                    bgColor={bgColor}
                    txtColor={txtColor}
                    name='Info'>
                    <TableInfo info={info} />
                </TableAccordion>
            </TableWrapper>
            <TableWrapper>
                <TableAccordion
                    bgColor={bgColor}
                    txtColor={txtColor}
                    name='Statuses'>
                    <TableStatuses statuses={statuses} setIsRotary={setIsRotary} setCriticalWarningText={() => false}
                        setIsTimesExist={() => false} isTimesExist={false} setDisabled={() => false} criticalWarningText={""}
                        isRotary={isRotary} isCheckerExists={selectedJob !== '' && selectedRun != ''} setStatuses={() => false} />
                </TableAccordion>
            </TableWrapper>
            <TableWrapper>
                <TableAccordion
                    bgColor={bgColor}
                    txtColor={txtColor}
                    name='Critical statuses'>
                    <TableStatuses statuses={criticalStatuses} setIsRotary={setIsRotary} setCriticalWarningText={() => false}
                        setIsTimesExist={() => false} isTimesExist={false} setDisabled={() => false} criticalWarningText={""}
                        isRotary={isRotary} isCheckerExists={selectedJob !== '' && selectedRun != ''} setStatuses={() => false} />
                </TableAccordion>
            </TableWrapper>
            <TableWrapper>
                <TableAccordion
                    bgColor={bgColor}
                    txtColor={txtColor}
                    name='Equipment'>
                    <TableEquipment equipments={equipments} />
                </TableAccordion>
            </TableWrapper>
            <TableWrapper>
                <TableAccordion
                    bgColor={bgColor}
                    txtColor={txtColor}
                    name='Equipment to write'>
                    <EquipmentCmbWrapper equimpentsToWriteView={equimpentsToWriteView} />
                </TableAccordion>
            </TableWrapper>
       </Box>
    );
}

