import React, { Component, useState } from 'react';
import _ from 'lodash';
import { styled, Paper, TableRow, TableCell, TableBody, TableHead, Table, TableContainer } from '@mui/material';
import TableHeader from './SortTable/TableHeader';


const SimpleValue = ({value}) => {
    return (
        <div>
            {RoundIfNumber(value)}
        </div>
    );
}

function RoundIfNumber(value) {
    if (_.isNumber(value)) {
       return _.round(value, 3);
    }
    return value;
}

const NewValue = ({value, defaultValue = '-'}) => {
    return (
        <div style={{color: 'green'}}>
            {value ? RoundIfNumber(value) : defaultValue}
        </div>
    );
}

const OldValue = ({value, defaultValue = '-'}) => {
    return (
        <div style={{color: 'red'}}>
            {value ? RoundIfNumber(value) : defaultValue}
        </div>
    );
}

const UpdateValue = ({value, defaultValue = '-'}) => {
    return (
        <div style={{color: 'orange'}}>
            {value ? value : defaultValue}
        </div>
    );
}

const equipmentViewType = {
    0: 'Insert',
    1: 'Remove',
    2: 'Update'
}

const TableCellSx = ({children, maxWidth = 100}) => {
    return (
        <TableCell sx={{maxWidth}}>
            {children}
        </TableCell>
    );
};

const Row = ({index, item}) => {
    const {
        job,
        run,
        valueType,                  
        description,
        equipmentNode,
        equipmentObject,
        newEquivalentHours,
        newDrillingHours,
        newOperatingHours,
        newCirculatingHours,
        newReamingHours,
        oldEquivalentHours,
        oldDrillingHours,
        oldOperatingHours,
        oldCirculatingHours,
        oldReamingHours,
        totalEquivalentHours,
        totalDrillingHours,
        totalOperatingHours,
        totalCirculatingHours,
        totalReamingHours,
        updateDate,
    } = item;

    if (equipmentViewType[valueType] === 'Update') {
        return (
            <TableRow key={index}>
                <TableCellSx> 
                    <UpdateValue value={equipmentObject}/>
                </TableCellSx>
                <TableCellSx> 
                    <UpdateValue value={equipmentNode}/>
                </TableCellSx>
                <TableCellSx> 
                    <UpdateValue value={description}/>
                </TableCellSx>
                <TableCellSx>
                    <NewValue value={newEquivalentHours}/>
                    <OldValue value={oldEquivalentHours}/>
                    <SimpleValue value={totalEquivalentHours}/>
                </TableCellSx>
                <TableCellSx> 
                    <NewValue value={newOperatingHours}/>
                    <OldValue value={oldOperatingHours}/>
                    <SimpleValue value={totalOperatingHours}/>
                </TableCellSx>
                <TableCellSx> 
                    <NewValue value={newCirculatingHours}/>
                    <OldValue value={oldCirculatingHours}/>
                    <SimpleValue value={totalCirculatingHours}/>
                </TableCellSx>
                <TableCellSx> 
                    <NewValue value={newDrillingHours}/>
                    <OldValue value={oldDrillingHours}/>
                    <SimpleValue value={totalDrillingHours}/>
                </TableCellSx>
                <TableCellSx> 
                    <NewValue value={newReamingHours}/>
                    <OldValue value={oldReamingHours}/>
                    <SimpleValue value={totalReamingHours}/>
                </TableCellSx>
            </TableRow>
        );
    }

    let NewOrOldCell = NewValue;
    if(equipmentViewType[valueType] === 'Remove') {
        NewOrOldCell = OldValue;
    }

    return (
        <TableRow key={index}>
            <TableCellSx> 
                <NewOrOldCell value={equipmentObject}/>
            </TableCellSx>
            <TableCellSx> 
                <NewOrOldCell value={equipmentNode}/>
            </TableCellSx>
            <TableCellSx> 
                <NewOrOldCell value={description}/>
            </TableCellSx>
            <TableCellSx> 
                <NewOrOldCell value={newEquivalentHours}/>
                <SimpleValue value={totalEquivalentHours}/>
            </TableCellSx>
            <TableCellSx> 
                <NewOrOldCell value={newOperatingHours}/>
                <SimpleValue value={totalOperatingHours}/>
            </TableCellSx>
            <TableCellSx> 
                <NewOrOldCell value={newCirculatingHours}/>
                <SimpleValue value={totalCirculatingHours}/>
            </TableCellSx>
            <TableCellSx> 
                <NewOrOldCell value={newDrillingHours}/>
                <SimpleValue value={totalDrillingHours}/>
            </TableCellSx>
            <TableCellSx> 
                <NewOrOldCell value={newReamingHours}/>
                <SimpleValue value={totalReamingHours}/>
            </TableCellSx>
        </TableRow>
    );
}

const Rows = ({data}) => {
    return (
        _.map(data, (v, index) => {  
            const key = `row_${index}`; 
            return (
                <Row
                    key={key}
                    index={index}
                    item={v}/>
            );
        })
    );
}

const keys = [           
    "equipmentObject",
    "equipmentNode",
    "description",
    "totalEquivalentHours",
    "totalOperatingHours",
    "totalCirculatingHours",
    "totalDrillingHours",
    "totalReamingHours"
]

function EquipmentCmbTable({equimpentsToWriteView}) {
    const [orderDirection, setOrderDirection] = useState('asc');
    const [valueToOrderBy, setValueToOrderBy] = useState(keys[0]);

    const handleRequestSort = (event, property) => {
        const isAscending = (valueToOrderBy === property && orderDirection === 'asc');
        setValueToOrderBy(property);
        setOrderDirection(isAscending ? 'desc' : 'asc')
    }

    const cols = [
        "SN Object",
        "SN Node",
        "Description",
        "Equivalent Hours",
        "Operating Hours",
        "Circulating Hours",
        "Drilling Hours",
        "Reaming Hours",
    ];

    let data = [];
    if (valueToOrderBy === "equipmentObject") {
        data = _.orderBy(equimpentsToWriteView, [valueToOrderBy, "equipmentNode"], [orderDirection, 'asc']);
    }
    else {
        data = _.orderBy(equimpentsToWriteView, [valueToOrderBy], [orderDirection]);
    } 

    return (
        <Paper sx={{ overflow: 'hidden' }}>
            <TableContainer sx={{ maxHeight: 440 }}>
                <Table stickyHeader aria-label="sticky table">
                    <TableHeader
                        valueToOrderBy={valueToOrderBy}
                        orderDirection={orderDirection}
                        handleRequestSort={handleRequestSort}
                        keys={keys}
                        columnColl={cols}
                    />
                    <TableBody>
                        <Rows data={data}/>
                    </TableBody>
                </Table>
            </TableContainer>
        </Paper>
    );
}

export class EquipmentCmbWrapper extends Component {
    static displayName = EquipmentCmbWrapper.name;

    render() {
        const { equimpentsToWriteView } = this.props;

        return (
            <Paper sx={{ overflow: 'hidden' }}>
                <EquipmentCmbTable title="Equipments to write" equimpentsToWriteView={equimpentsToWriteView}/>
            </Paper>
        );
    }
}
