import React, { useState } from 'react';
import TableHeader from './SortTable/TableHeader';
import TableContent from './SortTable/TableContent';
import { Paper, Table, TableBody, TableContainer } from '@mui/material';
import _ from 'lodash';
import { GetComparator, StableSort } from '../../Utils/SortUtil';

export function TableEquipmentDuplicates(props) {
    const [orderDirection, setOrderDirection] = useState('asc');
    const [valueToOrderBy, setValueToOrderBy] = useState(_.keys(props.dups[0])[0]);

    const handleRequestSort = (event, property) => {
        const isAscending = (valueToOrderBy === property && orderDirection === 'asc');
        setValueToOrderBy(property);
        setOrderDirection(isAscending ? 'desc' : 'asc')
    }

    const { dups } = props;
    return (
        <Paper sx={{ overflow: 'hidden' }}>
            <TableContainer sx={{ maxHeight: 440 }}>
                <Table stickyHeader aria-label="sticky table">
                    <TableHeader
                        valueToOrderBy={valueToOrderBy}
                        orderDirection={orderDirection}
                        handleRequestSort={handleRequestSort}
                        keys={_.take(_.keys(dups[0]),2)}
                        columnColl={['Serial Number', 'Description']}
                    />
                    <TableBody>
                        {
                            StableSort(dups, GetComparator(orderDirection, valueToOrderBy))
                                .map(({ item1, item2 }, index) => {
                                    let bgColor = "#ff9800"; //warning

                                    return (
                                        <TableContent
                                            key={index}
                                            index={index}
                                            primaryColor={bgColor}
                                            secondaryColor={bgColor}
                                            items={[item1, item2]}
                                            txtColor={"white"}
                                        />
                                    );
                            })
                        }
                    </TableBody>
                </Table>
            </TableContainer>
        </Paper>
    );
}
