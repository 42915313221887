import React, { useState, useEffect } from 'react';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import TableHeader from '../merge/Tables/SortTable/TableHeader';
import TableContent from '../merge/Tables/SortTable/TableContent';
import { GetComparator, StableSort } from '../Utils/SortUtil';
import TablePagination from '@mui/material/TablePagination';
import moment from 'moment';
import { ToDateFormat } from '../Utils/DateUtil';

export function CBMEquipmentTable({
    selected,
    setSelected,
    setScrollValue,
    CBM,
    scrollValue,
    page,
    setPage,
    rowsPerPage,
    setRowsPerPage,
    orderDirection,
    setOrderDirection,
    valueToOrderBy,
    setValueToOrderBy
}) {


    const handleClick = (event, items) => {
        let isNew = true;
        selected.forEach(item =>
        {
            if (item.id === items.id)
            {
                setSelected(selected.filter(item => item.id !== items.id));
                isNew = false;
                return;
            }
        });
        if (isNew)
        {
            setSelected(selected => [...selected, items]);
        } 
        setScrollValue(document.getElementById('tb-container').scrollTop);
    };

    const handleRequestSort = (event, property) => {
        const isAscending = (valueToOrderBy === property && orderDirection === 'asc');
        setValueToOrderBy(property);
        setOrderDirection(isAscending ? 'desc' : 'asc')
    }

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    useEffect(() => {
        if (document.getElementById('tb-container') != null)
            document.getElementById('tb-container').scrollTo(0, scrollValue);
    });

    const keys = ["isAlreadyWritten", "job", "run", "equipment",
        "eqH", "operH", "circH", "drillH", "reamH", "ref_date"];
    const columnCollection = ['Written', 'Job Number', 'Run Number', 'Equipment Number',
        'Equivalent Hours', 'Operating Hours', 'Circulating Hours', 'Drilling Hours', 'Reaming Hours', 'Date'];

    if (CBM != null) {
        const rows = StableSort(CBM, GetComparator(orderDirection, valueToOrderBy));
        return (
            <Paper sx={{ overflow: 'hidden', marginBottom: `1rem` }}>
                <TableContainer sx={{ maxHeight: 440, marginBottom: `1rem` }} id='tb-container'>
                    <Table stickyHeader aria-label="sticky table">
                        <TableHeader
                            valueToOrderBy={valueToOrderBy}
                            orderDirection={orderDirection}
                            handleRequestSort={handleRequestSort}
                            keys={keys}
                            columnColl={columnCollection}
                        />
                        <TableBody>
                            {
                                rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                    .map(({ isAlreadyWritten, id, equipment, eqH,
                                    circH, operH, drillH, reamH, job, run, ref_date }, index) =>
                                    {
                                        let bgColor = "white";
                                        let color = "black";
                                        let fontWeight = 400;
                                        const currentRow = {
                                            isAlreadyWritten, 
                                            id, equipment, eqH, circH, operH, drillH,
                                            reamH, job, run, ref_date
                                        };
                                        let isRowSelected = false;
                                        selected.forEach(item =>
                                        {
                                            if (item.id === id)
                                            {
                                                isRowSelected = true;
                                                return;
                                            }
                                        });
                                        if (isRowSelected)
                                        {
                                            bgColor = "#ff9800"; //warning
                                            color = '#3f3f3f';
                                            fontWeight = 700;
                                        }

                                        const date = ToDateFormat(ref_date);
                                        const items = [isAlreadyWritten, job, run, equipment, eqH, operH,
                                            circH, drillH, reamH, date];

                                        return (
                                            <TableContent
                                                key={index}
                                                index={index}
                                                primaryColor={bgColor}
                                                secondaryColor={bgColor}
                                                items={items}
                                                txtColor={color}
                                                handleClick={handleClick}
                                                currentRow={currentRow}
                                                fontWeight={fontWeight}
                                            />
                                        )
                                    })
                            }
                        </TableBody>
                    </Table>
                </TableContainer>
                <TablePagination
                    rowsPerPageOptions={[5, 10, 25, 50, 100, 250, 500, 1000]}
                    component="div"
                    count={rows.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                />
            </Paper>
        );
    } else
    {
        return;
    }
}
