import React from 'react';
import BurIcon from '../img/Burservice_logo_cyr_white.png';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import { styled } from '@mui/material/styles';
import { ThemeProvider, createTheme } from '@mui/material/styles';

import NavDrawer from './NavItems/NavDrawer';
import { Link } from 'react-router-dom';

const exampleTheme = createTheme({
    palette: {
        primary: {
            main: '#b71c1c',
        },
    },
});

const LightTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: theme.palette.common.white,
        color: 'rgba(0, 0, 0, 0.87)',
        boxShadow: theme.shadows[1],
    },
}));

export function NavMenu({
    AppBar,
    open,
    jobs,
    runs,
    setJobs,
    setRuns,
    setSelectJob,
    setSelectRun,
    handleDrawerOpen,
    handleDrawerClose,
    setOpen,
    drawerWidth,
    job,
    run,
    handleOpen,
    appRoutes
}) {

    return (
        <ThemeProvider theme={exampleTheme}>
            <AppBar position="sticky" color="primary" open={open} >
                <Container maxWidth="100%">
                    <Toolbar disableGutters>
                        <Box>
                            <IconButton onClick={handleDrawerOpen}>
                                <MenuIcon sx={{ color: 'white', ...(open && { display: 'none' }) }} />
                            </IconButton>
                        </Box>
                        <Link to='/'>
                            <img src={BurIcon}
                                alt="BurService"
                                width={150}
                                height={12}
                            />
                        </Link>
                        <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'flex' }, ml: 3 }}>
                            {appRoutes.map((page, index) => (
                                <Link key={index} to={page.path} style={{textDecoration: 'none'}}>
                                    <Button sx={{ my: 2, color: 'white', display: 'block' }}> {page.name} </Button>
                                </Link>
                            ))}
                        </Box>
                        <Box sx={{ flexGrow: 0, display: { xs: 'none', md: 'flex' } }}>
                            <LightTooltip title="Engineering Performance Operation Database">
                                <Typography> EPOD RUS </Typography>
                            </LightTooltip>
                        </Box>
                    </Toolbar>                
                </Container>
            </AppBar>
            <NavDrawer
                jobs={jobs}
                runs={runs}
                setJobs={setJobs}
                setRuns={setRuns}
                setSelectJob={setSelectJob}
                setSelectRun={setSelectRun}
                handleDrawerOpen={handleDrawerOpen}
                handleDrawerClose={handleDrawerClose}
                open={open}
                setOpen={setOpen}
                drawerWidth={drawerWidth}
                job={job}
                run={run}
                handleOpen={handleOpen}
            />
        </ThemeProvider>
    );
}

