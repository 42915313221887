import React, { useState, useEffect } from 'react';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import TableHeader from './SortTable/TableHeader';
import TableContent from './SortTable/TableContent';
import _ from 'lodash';
import { GetComparator, StableSort } from '../../Utils/SortUtil';

export function TableStatuses(props) {
    const { statuses, setStatuses, setDisabled, disabledWrite, criticalWarningText, setCriticalWarningText,
        setIsRotary, setIsTimesExist, isTimesExist, isRotary, isCheckerExists } = props;

    const [orderDirection, setOrderDirection] = useState('asc');
    const [valueToOrderBy, setValueToOrderBy] = useState(_.keys(statuses[0])[0]);

    const handleRequestSort = (event, property) => {
        const isAscending = (valueToOrderBy === property && orderDirection === 'asc');
        setValueToOrderBy(property);
        setOrderDirection(isAscending ? 'desc' : 'asc')
    }

    const getWarningText = (obj) =>
    {
        let warningText = obj.key.replace("correct", "incorrect. Please correct the name as mentioned in 1C ERP")
            .replace("before", "after")
            .replace("later", "earlier");
        if (warningText.includes("below rotary")) {
            warningText = warningText.concat('. Please correct the BRT time');
        } else if (warningText.includes("above rotary")) {
            warningText = warningText.concat('. Please correct the ART time');
        }
        return warningText;
    }

    useEffect(() => {
        if (isCheckerExists) {
            const filteredCriticalStatuses = statuses.filter(obj => obj.key == "Read time later than above rotary table time"
                || obj.key == "Initializing time before the below rotary time"
                || obj.key == "Mud motor name is correct");
            filteredCriticalStatuses.map((obj, index) => {
                if (!obj.value) {
                    console.log(obj.key, obj.value, criticalWarningText);
                    if (!disabledWrite) {
                        setDisabled(true);
                    }
                    let warningText = getWarningText(obj);
                    if (!criticalWarningText.includes(warningText)) {
                        const tempCriticalWarningText = warningText.concat('. ', criticalWarningText);
                        setCriticalWarningText(tempCriticalWarningText);
                    }
                } else
                {
                    let warningText = getWarningText(obj);
                    if (criticalWarningText.includes(warningText))
                    {
                        const tempCriticalWarningText = criticalWarningText.replace(warningText, "");
                        setCriticalWarningText(tempCriticalWarningText);
                    }
                }
            })
            if (!isRotary) {
                if (statuses.filter(obj => obj.key == "Rotary").length > 0) {
                    setIsRotary(true);
                }
            }
            if (isRotary && !isTimesExist) {
                const timesPairs = statuses.filter(obj => obj.key == "Read time later than above rotary table time"
                    || obj.key == "Initializing time before the below rotary time");
                if (timesPairs.length > 0) {
                    timesPairs.map((pair, index) => {
                        if (pair.value) {
                            setIsTimesExist(true);
                        }
                    })
                }
            }
        } else
        {
            if (criticalWarningText != '')
            {
                setCriticalWarningText('');
                setStatuses([]);
            }
        }
    });

    return (
        <Paper sx={{ overflow: 'hidden' }}>
          <TableContainer sx={{ maxHeight: 440 }}>
                <Table stickyHeader aria-label="sticky table">
                    <TableHeader
                        valueToOrderBy={valueToOrderBy}
                        orderDirection={orderDirection}
                        handleRequestSort={handleRequestSort}
                        keys={_.keys(statuses[0])}
                        columnColl={['Name', 'Result']}
                    />

                    <TableBody>
                        {
                            StableSort(statuses, GetComparator(orderDirection, valueToOrderBy))
                                .map(({ key, value }, index) => {
                                const isOk = value;
                                let bgColor = "#f44336"; //red
                                    let res = "False";
                                    if (isOk) {
                                        bgColor = "#9ccc65"; //green
                                        res = "Ok"
                                    }

                                    return (
                                        <TableContent
                                            key={index}
                                            index={index}
                                            primaryColor={bgColor}
                                            secondaryColor={bgColor}
                                            items={[key, res]}
                                            txtColor={'white'}
                                        />
                                    );
                            })
                        }
                        </TableBody>
                    </Table>
                </TableContainer>
            </Paper>
    );
}