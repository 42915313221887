import React, { useState} from 'react';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import TableHeader from './SortTable/TableHeader';
import TableContent from './SortTable/TableContent';
import _ from 'lodash';
import { GetComparator, StableSort } from '../../Utils/SortUtil';

export function TableEquipment(props) {
    const { equipments } = props;

    const [orderDirection, setOrderDirection] = useState('asc');
    const [valueToOrderBy, setValueToOrderBy] = useState(_.keys(props.equipments[0])[0]);

    const handleRequestSort = (event, property) => {
        const isAscending = (valueToOrderBy === property && orderDirection === 'asc');
        setValueToOrderBy(property);
        setOrderDirection(isAscending ? 'desc' : 'asc')
    }
        
    return (
        <Paper sx={{ overflow: 'hidden' }}>
            <TableContainer sx={{ maxHeight: 440 }}>
                <Table stickyHeader aria-label="sticky table">
                    <TableHeader
                        valueToOrderBy={valueToOrderBy}
                        orderDirection={orderDirection}
                        handleRequestSort={handleRequestSort}
                        keys={_.take(_.keys(equipments[0]),3)}
                        columnColl={['Serial Number', 'Description', 'Status']}
                    />
                    <TableBody>
                        {
                            StableSort(equipments, GetComparator(orderDirection, valueToOrderBy))
                                .map(({ item1, item2, item3, item4 }, index) => {
                                let bgColor = "#f44336"; //red;

                                if (item4) {
                                    bgColor = "#9ccc65"; //green
                                }

                                return (
                                    <TableContent
                                        key={index}
                                        index={index}
                                        primaryColor={bgColor}
                                        secondaryColor={bgColor}
                                        items={[ item1, item2, item3 ]}
                                        txtColor={'white'}
                                    />
                                )
                            })
                        }
                    </TableBody>
                </Table>
            </TableContainer>
        </Paper>
    ); 
}
