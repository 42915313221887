import moment, { isMoment } from 'moment';

export function ToDateFormat(date, format = "YYYY-MM-DD H:mm") {
    const currentDate = new Date();
    date = new Date(date) - 60000 * currentDate.getTimezoneOffset();
    if (isMoment(date)) {
        return date.format(format);
    }

    return moment(date).format(format);
}

export function ToDateShortFormat(date, format = "YYYY-MM-DD") {
    if (isMoment(date)) {
        return date.format(format);
    }
    
    return moment(date).format(format);
}