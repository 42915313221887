import React, { useState, useEffect } from 'react';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import TableHeader from '../merge/Tables/SortTable/TableHeader';
import TableContent from '../merge/Tables/SortTable/TableContent';
import { GetComparator, StableSort } from '../Utils/SortUtil';
import TablePagination from '@mui/material/TablePagination';
import moment from 'moment';
import { ToDateFormat } from '../Utils/DateUtil';
import _ from 'lodash';

export function CBMGeneralTable({
    selected,
    setScrollValue,
    filteredCBM, 
    CBM,
    scrollValue,
    page,
    setPage,
    rowsPerPage,
    setRowsPerPage,
    orderDirection,
    setOrderDirection,
    valueToOrderBy,
    setValueToOrderBy,
    setFilteredCBM
}) {


    const handleClick = (event, items) => {
        const tempFilteredCBM = CBM.filter((row) => row.run === items.run && row.job === items.job);
        if (_.isEqual(tempFilteredCBM, filteredCBM)) {
            setFilteredCBM([]);
        } else
        {
            setFilteredCBM(tempFilteredCBM);
        }
        setScrollValue(document.getElementById('tb-general-container').scrollTop);
    };

    const handleRequestSort = (event, property) => {
        const isAscending = (valueToOrderBy === property && orderDirection === 'asc');
        setValueToOrderBy(property);
        setOrderDirection(isAscending ? 'desc' : 'asc')
    }

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    useEffect(() => {
        if (document.getElementById('tb-container') != null)
            document.getElementById('tb-container').scrollTo(0, scrollValue);
    });

    const keys = ["isAlreadyWritten", "job", "run", "ref_date"];
    const columnCollection = ['Written', 'Job Number', 'Run Number', 'Date'];

    if (CBM != null) {
        const CBMWithoutEquipment = _.uniqWith(CBM.map((row) => {
            return ({ isAlreadyWritten: row.isAlreadyWritten, job: row.job, run: row.run, ref_date: row.ref_date });
        }), (rowA, rowB) =>
        {
            return (rowA.job === rowB.job && rowA.run == rowB.run);
        });
        const rows = StableSort(CBMWithoutEquipment, GetComparator(orderDirection, valueToOrderBy));
        return (
            <Paper sx={{ overflow: 'hidden', marginBottom: `1rem` }}>
                <TableContainer sx={{ maxHeight: 440, marginBottom: `1rem` }} id='tb-general-container'>
                    <Table stickyHeader aria-label="sticky table">
                        <TableHeader
                            valueToOrderBy={valueToOrderBy}
                            orderDirection={orderDirection}
                            handleRequestSort={handleRequestSort}
                            keys={keys}
                            columnColl={columnCollection}
                        />
                        <TableBody>
                            {
                                rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                    .map(({ isAlreadyWritten, job, run, ref_date }, index) =>
                                    {
                                        let primaryColor = "white";
                                        let secondaryColor = "white";
                                        let color = "black";
                                        let fontWeight = 400;
                                        const currentRow = {
                                            isAlreadyWritten, job, run, ref_date
                                        };
                                        const tempFilteredCBM = _.sortBy(CBM.filter((row) => row.job === job && row.run === run));
                                        const filteredSelected = _.sortBy(selected.filter((row) => row.job == job && row.run == run));
                                        const isRowSelected = _.xorBy(tempFilteredCBM, filteredSelected, "id").length === 0 && tempFilteredCBM.length != 0 && filteredSelected.length != 0;
                                        const isRowPartlySelected = _.intersection((tempFilteredCBM, filteredSelected)).length === filteredSelected.length
                                            && filteredSelected.length != 0 && tempFilteredCBM.length != 0;

                                        if (isRowSelected) {
                                            primaryColor = "#ff9800"; //warning
                                            secondaryColor = "#ff9800"; //warning
                                            color = '#3f3f3f';
                                            fontWeight = 700;
                                        } else if (isRowPartlySelected)
                                        {
                                            primaryColor = "#ff9800"; //warning
                                            secondaryColor = "white";
                                            color = '#3f3f3f';
                                            fontWeight = 700;
                                        }

                                        const date = ToDateFormat(ref_date);
                                        const items = [isAlreadyWritten, job, run, date];

                                        return (
                                            <TableContent
                                                key={index}
                                                index={index}
                                                primaryColor={primaryColor}
                                                secondaryColor={secondaryColor}
                                                items={items}
                                                txtColor={color}
                                                handleClick={handleClick}
                                                currentRow={currentRow}
                                                fontWeight={fontWeight}
                                            />
                                        )
                                    })
                            }
                        </TableBody>
                    </Table>
                </TableContainer>
                <TablePagination
                    rowsPerPageOptions={[5, 10, 25, 50, 100, 250, 500, 1000]}
                    component="div"
                    count={rows.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                />
            </Paper>
        );
    } else
    {
        return;
    }
}
