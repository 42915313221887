import React, { useState, useEffect } from 'react';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import TableHeader from '../merge/Tables/SortTable/TableHeader';
import _ from 'lodash';
import BlockIcon from '@mui/icons-material/Block';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import { TableCell, TableRow, Typography, TablePagination } from '@mui/material';
import { ToDateFormat } from '../Utils/DateUtil';

function getInsiteFileHistory(setTotalLength, setData, pageNumber, pageSize) {
    fetch(`/erp/getInsiteFileHistory?pageNumber=${pageNumber}&pageSize=${pageSize}`)
        .then((res) => res.json())
        .then((insiteFiles) => {
            console.log(insiteFiles);
            setData([...insiteFiles.files]);
            setTotalLength(insiteFiles.totalCount);
        });
}

export function InsiteFileHistoryTable({
    changes
}) {
    const [data, setData] = useState([]);
    const [totalLength, setTotalLength] = useState(0);

    const fetchData = (page, pageSize) =>
    {
        getInsiteFileHistory(setTotalLength, setData, page, pageSize);
    }

    useEffect(() => { }, [data]);

    console.log(data);

    return (
        <InsiteFileHistoryTableInner data={data} totalLength={totalLength} fetchData={fetchData.bind(this)} />
    );
}

function CheckTrinaryItemCbm(hasItem) {
    if (_.isNil(hasItem)) {
        return (
            <Typography color="red" variant="body1" fontWeight={700}>
                Deleted
            </Typography>
        );
    }

    if (hasItem) {
        return <CheckCircleOutlineIcon/>;
    }

    return <BlockIcon/>;
}

function Rows({data}) {
    const rows = _.map(data, ({ fileName, createdDate, hasMdb,
        hasCbm, job, run, runEndTime, dayDifference }, index) =>
    {
        let bgColor = null;
        let color = 'black';

        const hasMdbView = CheckTrinaryItemCbm(hasMdb);
        const hasCbmView = CheckTrinaryItemCbm(hasCbm);
        const date = ToDateFormat(createdDate);

        return (
            <TableRow 
                key={`row_${index}`} 
                sx={{ background: bgColor }}>

                <TableCell key={`cell_${index}_fileName`} sx={{ color: color }}> 
                    {fileName}
                </TableCell>
                <TableCell key={`cell_${index}_createdDate`} sx={{ color: color }}> 
                    {date}
                </TableCell>
                <TableCell key={`cell_${index}_job`} sx={{ color: color }}> 
                    {job}
                </TableCell>
                <TableCell key={`cell_${index}_run`} sx={{ color: color }}> 
                    {run}
                </TableCell>
                <TableCell key={`cell_${index}_hasMdbView`} sx={{ color: color }}> 
                    {hasMdbView}
                </TableCell>
                <TableCell key={`cell_${index}_hasCbmView`} sx={{ color: color }}> 
                    {hasCbmView}
                </TableCell>
                <TableCell key={`cell_${index}_runEndTime`} sx={{ color: color }}>
                    {runEndTime}
                </TableCell>
                <TableCell key={`cell_${index}_dayDifference`} sx={{ color: color }}>
                    {dayDifference}
                </TableCell>
            </TableRow>
        );
    });
    return rows;
}

function InsiteFileHistoryTableInner({data, totalLength, fetchData}) {
    const [orderDirection, setOrderDirection] = useState('desc');
    const [valueToOrderBy, setValueToOrderBy] = useState('createdDate');
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(5);

    const handleRequestSort = (event, property) => {
        const isAscending = (valueToOrderBy === property && orderDirection === 'asc');
        setValueToOrderBy(property);
        setOrderDirection(isAscending ? 'desc' : 'asc')
    }

    const values = _.orderBy(data, [valueToOrderBy], [orderDirection]);

    const keys = ["fileName", "createdDate", "job", "run", "hasMdb",
        "hasCbm", "runEndTime", "dayDifference"];
    const cols = ["File Name", "Upload Date", "Job", "Run",
        "Has mdb", "Has cmb", "Run end time", "Day difference"];
    const widths = ['300px', '300px', "100px", "100px", "10px", "10px"];

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    useEffect(() => {
        fetchData(page, rowsPerPage);
    }, [page, rowsPerPage]);

    console.log(values);

    return (
        <Paper sx={{ overflow: 'hidden' }}>
            <TableContainer sx={{ maxHeight: 440 }} id='tb-container'>
                <Table stickyHeader aria-label="sticky table">
                    <TableHeader
                        valueToOrderBy={valueToOrderBy}
                        orderDirection={orderDirection}
                        handleRequestSort={handleRequestSort}
                        keys={keys}
                        columnColl={cols}
                        widthCols={widths}
                    />
                    <TableBody>
                        <Rows data={values}/>
                    </TableBody>
                </Table>
            </TableContainer>
            <TablePagination
                rowsPerPageOptions={[5, 10, 25, 50, 100, 250, 500, 1000]}
                component="div"
                count={totalLength}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
            />
        </Paper>
    );
}
