import React, { useState } from 'react';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Checkbox from '@mui/material/Checkbox';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import _ from 'lodash';

const checkGroups = [['MWD Service Interrupt', 'si'],
    ['Trip For MWD', 'tf'],
    ['Trip For DD', 'ddTf'],
    ['DD Service Interrupt', 'ddSi'],
    ['Exceeded Spec/Cust Damage', 'ca']];
const fieldGroups = [['DD NPT', 'ddRigHrs'],
    ['MWD NPT', 'rigHrs'],
    ['NPT Due to Exc Spec/Cust Damage', 'cusHrs']];

function useForceUpdate() {
    const [value, setValue] = useState(0);
    return () => setValue(value => value + 1);
}

function setGroupValue(detailInfo, group, value) {
    const groupOneKey = group[1];
    if (!detailInfo || !groupOneKey) {
        return;
    }
    
    detailInfo[groupOneKey] = value;
}

function getGroupValue(detailInfo, group, defValue) {
    const groupOneKey = group[1];
    if (!detailInfo || !groupOneKey) {
        return defValue;
    }

    const details = detailInfo[groupOneKey];
    return details === null ? defValue : details;
}

export function ItemAccordionRun({
    detailInfo, setDetailInfo
}) {
    const forceUpdate = useForceUpdate();

    const getGroupLabel = (group) => {
        return group[0];
    } 

    return (
        <Box
            component="form"
            autoComplete="off"
            sx={{
            '& .MuiTextField-root': { m: 1, width: '25ch' },
            }}
        >
            {fieldGroups.map((group) => {
                 const key = getGroupLabel(group);
                 return (
                    <TextField
                         variant="outlined"
                         label={key}
                         key={key}
                         value={getGroupValue(detailInfo, group, '')}
                         inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }} 
                         onChange={(e) => {
                            const val = e.target.value;
                             const value = !isNaN(parseFloat(val)) && !isNaN(val)
                                 ? val.startsWith("0.")
                                     ? val
                                     : val === '0'
                                         ? val
                                         : val.replace(/^0+/, '')
                                 : val === ""
                                     ? null
                                     : getGroupValue(detailInfo, group, 0); 
                            setGroupValue(detailInfo, group, value);
                            setDetailInfo(detailInfo);
                            forceUpdate();
                        }}
                    />
                );
            })}
            <FormGroup>
                {checkGroups.map((group) => {
                    const key = getGroupLabel(group);
                    return (
                        <FormControlLabel
                            key={key}
                            control={<Checkbox />}
                            label={key}
                            checked={getGroupValue(detailInfo, group, false)}
                            onChange={(e) => {
                                const val = e.target.checked;
                                setGroupValue(detailInfo, group, val);
                                setDetailInfo(detailInfo);
                                forceUpdate();
                            }}
                            sx={{width:`15vw`}}
                        />
                    );
                })}
            </FormGroup>
        </Box>
    );
}