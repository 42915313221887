import React, { useState } from 'react';
import FileSaver from 'file-saver';
import { LoadingWindow } from '../common/LoadingWindow'
import { AlertNotification } from '../common/AlertNotification';
import { Button, Tooltip } from '@mui/material';

export function ButtonSend(props) {
    const [isOpen, setIsOpen] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [messageInfo, setMessageInfo] = useState("File not found"); //if fetch doesn't work
    const [alertState, setAlertState] = useState("error");

    const processExportResponse = (res) =>
    {
        if (res.ok) {
            setAlertState("success");
            setMessageInfo("Excel has been sent");
        }
        else {
            const error = res.json();
            error.then((ex) => {
                setMessageInfo(`Failed to send excel: ${ex.error}`);
                setIsLoading(false);
                setAlertState("error");
            });
        }
    }

    const fetchExportChecker = (requestOptions) =>
    {
        console.log(props.fileName);
        fetch("file/SendExcel/", requestOptions)
            .then((res) => {
                console.log("response: ", res.ok)
                processExportResponse(res);
                res.blob().then((MyBlob) => {
                    FileSaver.saveAs(MyBlob, `${props.fileName.replace('.zip', '')
                        .replace('/tmp/Files/', '')}.xlsx`);
                    setIsLoading(false);
                    setIsOpen(true);
                    console.log("res.blob is ok")
                });
            });
    }

    const sendMessage = () => {
        setIsLoading(true);

        const requestOptions = {
            method: "POST",
            headers: { "Content-Type": "application/json" },  
            responseType: "blob",
            body: JSON.stringify(props.fileName)
        };

        fetchExportChecker(requestOptions);
    }

    return (
        <div>
            <Tooltip title="Send message about validation falls">
                <Button color="error"
                    variant="outlined"
                    style={{ marginBottom: '1rem' }}
                    onClick={sendMessage}
                >
                    Export checker to excel
                </Button>
            </Tooltip>
            <AlertNotification
                alertState={alertState}
                messageInfo={messageInfo}
                setIsOpen={setIsOpen.bind(this)}
                isOpen={isOpen}
            />
            <LoadingWindow isLoading={isLoading} loadDesc={"export files..."} />
        </div>
    );
}

