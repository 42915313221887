import React, { useState, useEffect } from 'react';
import { Route, Routes, useSearchParams } from 'react-router-dom';
//import AppRoutes from './AppRoutes';
import { Layout } from './components/Layout';
import './custom.css';
import { Home } from "./components/MainPage/Home";
import { Merge } from "./components/merge/Merge";
import { ImportFrom1C } from './components/import1C/ImportOneCView';
import { Whitelist } from './components/whitelist/Whitelist';
import Login from "./Login";
import _ from 'lodash';

export default function App() {

    const [isInit, setIsInit] = React.useState(true);
    const [role, setRole] = React.useState();
    const [code, setCode] = React.useState();
    const [restricted, setRestricted] = React.useState(false);
    const [searchParams, setSearchParams] = useSearchParams();
    const [jobs, setJobs] = useState([]);
    const [runs, setRuns] = useState([]);
    const [selectedJob, setSelectJob] = useState("");
    const [selectedRun, setSelectRun] = useState("");
    const [checkItem, setCheckItem] = useState({ 'info': [], 'statuses': [], 'criticalStatuses': [], 'equipments': [], 'equimpentsToWriteView': [] });
    const [info, setInfo] = useState([]);
    const [statuses, setStatuses] = useState([]);
    const [criticalStatuses, setCriticalStatuses] = useState([]);
    const [equipments, setEquipments] = useState([]);
    const [equimpentsToWriteView, setEquimpentsToWriteView] = useState([]);
    const [isRotary, setIsRotary] = useState(false);
    const [detailInfo, setDetailInfo] = useState({
        'ddRigHrs': '',
        'rigHrs': '',
        'cusHrs': '',
        'si': false,
        'tf': false,
        'ddTf': false,
        'ddSi': false,
        'ca': false,
    });

    const handleOpen = (run, job) => {
        fetch(`/rj/GetInfoRuns/?run=${run}&job=${job}`)
            .then((res) => {
                console.log("res is", res)
                return res.json();
            })
            .then((res) => {
                const tempCheckItem = res.checkItem;
                if (!_.isEqual(tempCheckItem, checkItem)) {
                    setCheckItem(tempCheckItem);
                    setInfo(tempCheckItem.info);
                    setStatuses(tempCheckItem.statuses);
                    setCriticalStatuses(tempCheckItem.criticalStatuses);
                    setEquipments(tempCheckItem.equipments);
                    setEquimpentsToWriteView(tempCheckItem.equimpentsToWriteView);
                }
                setDetailInfo(res.detailInfo[0]);
            });
    };


    const tempCode = searchParams.get("code");

    let component = <Login />;

    let AppRoutes = [
    ];

    if (role === "Field") {
        AppRoutes = [{
            index: true,
            path: '/',
            element: <Merge
                job={selectedJob}
                run={selectedRun}
                role={role}
            />,
            name: 'Merge'
        }];
    } else if (role === 'FSQC') {
        AppRoutes = [{
            index: true,
            element: <Home
                selectedJob={selectedJob}
                selectedRun={selectedRun}
                detailInfo={detailInfo}
                setDetailInfo={setDetailInfo}
                info={info}
                statuses={statuses}
                setIsRotary={setIsRotary}
                isRotary={isRotary}
                criticalStatuses={criticalStatuses}
                equipments={equipments}
                equimpentsToWriteView={equimpentsToWriteView}
            />,
            path: '/',
            name: "Explorer Quick View"
        },
        {
            path: '/Merge',
            element: <Merge
                job={selectedJob}
                run={selectedRun}
                role={role}
            />,
            name: 'Merge'
        },
        {
            path: '/1C_ERP',
            element: <ImportFrom1C />,
            name: '1C ERP'
        }]
    } else if (role === 'Admin')
    {
        AppRoutes = [{
            index: true,
            element: <Home
                selectedJob={selectedJob}
                selectedRun={selectedRun}
                detailInfo={detailInfo}
                setDetailInfo={setDetailInfo}
                info={info}
                statuses={statuses}
                setIsRotary={setIsRotary}
                isRotary={isRotary}
                criticalStatuses={criticalStatuses}
                equipments={equipments}
                equimpentsToWriteView={equimpentsToWriteView}
            />,
            path: '/',
            name: "Explorer Quick View"
        },
        {
            path: '/Merge',
            element: <Merge
                job={selectedJob}
                run={selectedRun}
                role={role}
            />,
            name: 'Merge'
        },
        {
            path: '/1C_ERP',
            element: <ImportFrom1C />,
            name: "1C ERP"
        },
        {
            path: '/Whitelist',
            element: <Whitelist />,
            name: "Whitelist"
        }
        ];
    }

    if (code && !restricted) {
        component =
            <Layout role={role}
                jobs={jobs}
                runs={runs}
                setJobs={setJobs}
                setRuns={setRuns}
                setSelectJob={setSelectJob}
                setSelectRun={setSelectRun}
                job={selectedJob}
                run={selectedRun}
                handleOpen={handleOpen}
                appRoutes={AppRoutes}
            >
                <Routes>
                {AppRoutes.map((route, index) => {
                    const { element, ...rest } = route;
                    return <Route key={index} {...rest} element={element} />;
                })}
                </Routes>
            </Layout>;
    } else {
        if (tempCode) {
            setCode(tempCode);
        }
    }


    React.useEffect(() => {
        if (isInit && code) {
            let isOk = true;
            fetch(`whitelist/GetRole?code=${code}`).then((res) => {
                if (res.ok) { return res.json(); } else {
                    isOk = false;
                }
            }).then((res) => {
                if (isOk) {
                    setRole(res.role);
                } else {
                    setRestricted(true);
                }
                setIsInit(false);
            });
            searchParams.delete("code");
            searchParams.delete("session_state");
            setSearchParams(searchParams);
        }
    }, [code]);

    return (
        component
    );
}
