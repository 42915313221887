import React, { useState, useEffect } from 'react';
import MuiAppBar from '@mui/material/AppBar';
import { Container} from 'reactstrap';
import { NavMenu } from './app-nav/NavMenu';
import { styled, useTheme } from '@mui/material/styles';

import Box from '@mui/material/Box';

export function Layout({
    setJobs,
    setRuns,
    setSelectRun,
    setSelectJob,
    jobs,
    runs,
    job,
    run,
    children,
    setIsRunJobInit,
    handleOpen,
    appRoutes
}) {

    const drawerWidth = 240;

    const theme = useTheme();
    const [open, setOpen] = useState(false);

    const handleDrawerOpen = () => {
        setOpen(true);
    };

    const handleDrawerClose = () => {
        setOpen(false);
    };

    const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })(
        ({ open }) => ({
            flexGrow: 1,
            padding: theme.spacing(3),
            transition: (theme) => theme.transitions.create('margin', {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.leavingScreen,
            }),
            marginLeft: 0,
            ...(open && {
                transition: (theme) => theme.transitions.create('margin', {
                    easing: theme.transitions.easing.easeOut,
                    duration: theme.transitions.duration.enteringScreen,
                }),
                marginLeft: `${drawerWidth}px`,
            }),
        }),
    );

    const AppBar = styled(MuiAppBar, {
        shouldForwardProp: (prop) => prop !== 'open',
    })(
        ({ open }) => ({
            transition: (theme) => theme.transitions.create(['margin', 'width'], {
                easing: theme.transitions.easing.sharp,
                duration: 5000,
            }),
            ...(open && {
                width: `-calc(100% - ${drawerWidth}px)`,
                marginLeft: `${drawerWidth}px`,
                transition: (theme) => theme.transitions.create(['margin', 'width'], {
                    easing: theme.transitions.easing.easeOut,
                    duration: 5000,
                }),
            }),
        }));

    return (
        <div>
            <NavMenu
                jobs={jobs}
                runs={runs}
                setJobs={setJobs}
                setRuns={setRuns}
                setSelectJob={setSelectJob}
                setSelectRun={setSelectRun}
                handleDrawerOpen={handleDrawerOpen}
                handleDrawerClose={handleDrawerClose}
                open={open}
                setOpen={setOpen}
                drawerWidth={drawerWidth}
                AppBar={AppBar}
                job={job}
                run={run}
                handleOpen={handleOpen}
                appRoutes={appRoutes}
            />
            <Box sx={{ mt: 2 }}>
                <Container fluid={true}>
                    <Main open={open} sx={{ mt: 2 }} theme={theme}>
                        {children}
                    </Main>
                </Container>
            </Box>
      </div>
    );
  }