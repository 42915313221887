import React from 'react';
import Snackbar from '@mui/material/Snackbar';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';

export function AlertNotification(props) {

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        props.setIsOpen(false);
    };
    const action = (
        <React.Fragment>
            <IconButton
                size="small"
                aria-label="close"
                color="inherit"
                onClick={handleClose}
            >
                <CloseIcon fontSize="small" />
            </IconButton>
        </React.Fragment>
    )
    return (
        <div>
            <Snackbar
                open={props.isOpen}
                autoHideDuration={5000} //5 seconds
                onClose={handleClose}
                action={action}
            >
                <Alert elevation={6} variant="filled" onClose={handleClose} severity={props.alertState} sx={{ width: '100%' }}>
                    <AlertTitle> {props.alertState} </AlertTitle>
                    {props.messageInfo}
                </Alert>
            </Snackbar>
        </div>
    );
}

