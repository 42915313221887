import React from 'react';
import FileSaver from 'file-saver';
import Button from '@mui/material/Button';
import moment from 'moment';
import { ToDateShortFormat } from '../Utils/DateUtil';


export function ButtonExport({
    setIsLoading, 
    setLoadingDescription,
    setIsOpen,
    setAlertState,
    setMessageInfo
}) {

    const processExportResponse = (res) =>
    {
        if (res.ok) {
            setAlertState("success");
            setMessageInfo("excel has been sent");
        }
        else {
            setAlertState("error");
            setMessageInfo("Failed to send exel");
        }
    }

    const fetchExport = (requestOptions) =>
    {
        fetch("/erp/Export", requestOptions)
            .then((res) => {
                console.log("response: ", res.ok);

                processExportResponse(res);

                res.blob().then((MyBlob) => {
                    FileSaver.saveAs(MyBlob, 'Выгрузка для 1C ERP от ' + ToDateShortFormat(moment()) + '.xlsx');
                    setIsLoading(false);
                    setIsOpen(true);
                    console.log("res.blob is ok")
                });
            });
    }

    const sendMessage = () => {
        const requestOptions = {
            method: "POST",
            responseType: "blob",            
        };
        setIsLoading(true);
        setLoadingDescription("export files...");
        fetchExport(requestOptions);

    }

    return (
        <div>
            <Button color="error"
                variant="outlined"
                style={{ marginBottom: '1rem' }}
                onClick={sendMessage}
            >
                Export to 1C ERP
            </Button>
        </div>
    );
}

