import React, { useState } from 'react';

import { AlertNotification } from '../common/AlertNotification';
import { LoadingWindow } from '../common/LoadingWindow';
import { Button, Box, Stack, Typography, TextField, FormControl } from '@mui/material';
import { Accordion, AccordionDetails, AccordionSummary} from '@mui/material';
import { WhitelistTable } from './WhitelistTable';
import UserComboBox from './UserComboBox';
import _, { toLower } from 'lodash';

function TableAccordion({
    name,
    children,
    bgColor = "#d32f2f",
    txtColor = "white"
}) {
    return (
        <Accordion>
            <AccordionSummary sx={{ background: bgColor }}>
                <Typography sx={{ color: txtColor }}>
                    {name}
                </Typography>
            </AccordionSummary>
            <AccordionDetails>{children}</AccordionDetails>           
        </Accordion>
    );
}

const TableWrapper = (function TableWrapper(props) {
    return (
        <Box sx={{marginBottom: `0.5rem`}} {...props} />
    );
});

const CustomTextField = (function CustomTextField(props) {
    return (
        <TextField
            label={props.label}
            variant="outlined"
            value={props.value}
            id="UserIdToAdd"
            onChange={(e) => props.setUserIdToAdd(e.target.value)}
            sx={props.sx}
            size='small'
        />)
});

export function Whitelist() {
    function ButtonHelper()
    {
        return (
            <Stack direction="row" spacing={2} sx={{ marginLeft: '1rem', alignItems: 'center' }}>
                <Button
                    color="error"
                    variant="outlined"
                    component="label"
                    disabled={currentUser['id'] === ''}
                    onClick={() => {
                        handleAddToWhitelist();
                    }}
                >
                    Add to whitelist
                </Button>
            </Stack>
        );
    }
    const [loadingDescription, setLoadingDescription] = useState();
    const [isOpen, setIsOpen] = useState(false);
    const [whitelist, setWhitelist] = useState();
    const [isLoading, setIsLoading] = useState(false);
    const [messageInfo, setMessageInfo] = useState("File not found"); //if fetch doesn't work
    const [alertState, setAlertState] = useState("error");
    const [scrollValue, setScrollValue] = useState(0);
    const [isInit, setIsInit] = useState(true);
    const [roles, setRoles] = useState([]);
    const [users, setUsers] = useState([]);
    const [currentUser, setCurrentUser] = useState({id: '', fullname: '', department: '', email: '', position: ''})

    const handleLoad = () =>
    {
        fetchWhitelist();
    }

    const fetchWhitelist = () => {        
        setIsLoading(true); 
        setLoadingDescription("Loading whitelist table");

        fetch("/whitelist/GetWhitelist")
            .then((res) =>
            {
                setIsLoading(false);
                return res.json();
            })
            .then((res) =>
            {
                console.log("get check json", res);
                if (res && _.isArray(res)) {
                    setWhitelist(res);
                }
            });

        fetch("/whitelist/GetRoles")
            .then((res) => {
                setIsLoading(false);
                return res.json();
            })
            .then((res) => {
                console.log("get check json", res);
                if (res && _.isArray(res)) {
                    setRoles(res);
                }
            });

        fetch("/whitelist/GetUsers")
            .then((res) => {
                setIsLoading(false);
                return res.json();
            })
            .then((res) => {
                console.log("get check json", res);
                if (res && _.isArray(res)) {
                    setUsers(res);
                }
            });
    }

    if (isInit) {
        handleLoad();
        console.log('init!!!');
        setIsInit(false);
    }

    const handleAddToWhitelist = () =>
    {
        setIsLoading(true);
        setLoadingDescription("Submitting...");

        const requestOptions =
        {
            method: "POST",
            headers:
            {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(currentUser),
        };

        fetch(`/whitelist/AddToWhitelist`, requestOptions)
            .then((res) => {
                setIsLoading(false);
                setIsOpen(true);
                if (res.ok) {
                    setAlertState("success");
                    setMessageInfo(`Successful request`);
                    handleLoad();
                    return res.json();
                }
                else {
                    setAlertState("error");
                    const error = res.json();
                    error.then((ex) => {
                        setMessageInfo(`Failed to perform request: ${ex.error}`);
                    });
                }
            });
    }

    const handleRemoveFromWhitelist = (index) => {
        setIsLoading(true);
        setLoadingDescription("Submitting...");

        const requestOptions =
        {
            method: "POST",
            headers:
            {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(whitelist.filter((item) => item.id == index)),
        };

        fetch(`/whitelist/RemoveFromWhitelist`, requestOptions)
            .then((res) => {
                setIsLoading(false);
                setIsOpen(true);
                if (res.ok) {
                    setAlertState("success");
                    setMessageInfo(`Successful remove`);
                    handleLoad();
                    return res.json();
                }
                else {
                    setAlertState("error");
                    const error = res.json();
                    error.then((ex) => {
                        setMessageInfo(`Failed to perform remove: ${ex.error}`);
                    });
                }
            });
    }

    const handleChangeRole = (index, e) => {
        setIsLoading(true);
        setLoadingDescription("Submitting...");

        const requestOptions =
        {
            method: "PUT",
            headers:
            {
                'Content-Type': 'application/json'
            }
        };

        fetch(`/whitelist/ChangeRole?recordId=${index}&roleId=${e.target.value}`, requestOptions)
            .then((res) => {
                setIsLoading(false);
                setIsOpen(true);
                if (res.ok) {
                    setAlertState("success");
                    setMessageInfo(`Successful role change`);
                    handleLoad();
                    return res.json();
                }
                else {
                    setAlertState("error");
                    const error = res.json();
                    error.then((ex) => {
                        setMessageInfo(`Failed to perform action: ${ex.error}`);
                    });
                }
            });
    }

    return (
        <div style={{ height: '100%' }}>
            <WhitelistView 
                whitelist={whitelist}
                scrollValue={scrollValue}
                setScrollValue={setScrollValue}
                ButtonHelper={ButtonHelper}
                roles={roles}
                handleRemoveFromWhitelist={handleRemoveFromWhitelist}
                handleChangeRole={handleChangeRole}
                users={users}
                currentUser={currentUser}
                setCurrentUser={setCurrentUser}
            />
            <AlertNotification
                alertState={alertState}
                messageInfo={messageInfo}
                setIsOpen={setIsOpen.bind(this)}
                isOpen={isOpen}
            />
            <LoadingWindow isLoading={isLoading} loadDesc={loadingDescription} />
        </div>
    );
}

const WhitelistView = ({
    whitelist,
    scrollValue,
    setScrollValue,
    ButtonHelper,
    users,
    currentUser,
    setCurrentUser,
    roles,
    handleRemoveFromWhitelist,
    handleChangeRole
}) => {
    return (
        <TableWrapper>
            <TableAccordion name='Whitelist table'>
                <WhitelistTable 
                    whitelist={whitelist}
                    scrollValue={scrollValue}
                    setScrollValue={setScrollValue}
                    roles={roles}
                    handleRemoveFromWhitelist={handleRemoveFromWhitelist}
                    handleChangeRole={handleChangeRole}
                />
                <Stack direction='row' spacing={2}>
                    <UserComboBox
                        label='User to add'
                        values={users}
                        handleChange={setCurrentUser}
                        value={currentUser}
                        width={'15vw'}
                    />
                    <ButtonHelper />
                </Stack>
            </TableAccordion>
        </TableWrapper>
    );
}