import React, { useState, useEffect } from 'react';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import TableHeader from '../merge/Tables/SortTable/TableHeader';
import WhitelistTableContent from './WhitelistTableContent';
import { GetComparator, StableSort } from '../Utils/SortUtil';

export function WhitelistTable({
    selected,
    setSelected,
    setScrollValue,
    whitelist,
    scrollValue,
    roles,
    handleRemoveFromWhitelist,
    handleChangeRole
}) {
    const [orderDirection, setOrderDirection] = useState('asc');
    const [valueToOrderBy, setValueToOrderBy] = useState();

    const handleRequestSort = (event, property) => {
        const isAscending = (valueToOrderBy === property && orderDirection === 'asc');
        setValueToOrderBy(property);
        setOrderDirection(isAscending ? 'desc' : 'asc')
    }

    useEffect(() => {
        if (document.getElementById('tb-container-whitelist') != null)
            document.getElementById('tb-container-whitelist').scrollTo(0, scrollValue);
    });

    const keys = ["delete", "userId", "name", "role"];
    const columnCollection = ['Delete?','User Id', 'Name', 'Role'];

    if (whitelist != null) {
        return (
            <Paper sx={{ overflow: 'hidden', marginBottom:`1rem` }}>
                <TableContainer sx={{ maxHeight: `50vh` }} id='tb-container-whitelist'>
                    <Table stickyHeader aria-label="sticky table">
                        <TableHeader
                            valueToOrderBy={valueToOrderBy}
                            orderDirection={orderDirection}
                            handleRequestSort={handleRequestSort}
                            keys={keys}
                            columnColl={columnCollection}
                        />
                        <TableBody>
                            {
                                StableSort(whitelist, GetComparator(orderDirection, valueToOrderBy))
                                    .map(({ id, userId, name, role }, index) => {
                                        let color = "black";
                                        const currentRow = {
                                            id, userId, name, role
                                        };
                                        const items = [id, userId, name, role];

                                        return (
                                            <WhitelistTableContent
                                                key={index}
                                                index={index}
                                                items={items}
                                                txtColor={color}
                                                currentRow={currentRow}
                                                roles={roles}
                                                handleRemoveFromWhitelist={handleRemoveFromWhitelist}
                                                handleChangeRole={handleChangeRole}
                                            />
                                        )
                                    })
                            }
                        </TableBody>
                    </Table>
                </TableContainer>
            </Paper>
        );
    } else
    {
        return;
    }
}
