import {
    TableCell, TableRow, Select,
    MenuItem, FormControl, InputLabel,
    IconButton
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import _ from 'lodash';
import React from 'react';


export default function WhitelistTableContent({
    key,
    index,
    items,
    txtColor,
    currentRow,
    roles,
    handleRemoveFromWhitelist,
    handleChangeRole
})

{
    const keyPart = items[index] || "empty";
    return (
        <TableRow
            key={`row_${index}_${keyPart}`} 
        >
            <TableCell key={`cell_${index}_1}`} sx={{ color: txtColor }}>
                <IconButton onClick={() => handleRemoveFromWhitelist(currentRow["id"])}>
                    <CloseIcon />
                </IconButton>
            </TableCell>
            <TableCell key={`cell_${index}_2}`} sx={{ color: txtColor }}>
                {currentRow["userId"]}
            </TableCell>
            <TableCell key={`cell_${index}_3}`} sx={{ color: txtColor }}>
                {currentRow["name"]}
            </TableCell>
            <TableCell key={`cell_${index}_4}`} sx={{ color: txtColor }}>
                <FormControl sx={{ width: '50%' }}>
                    <InputLabel htmlFor={`role-${index}-select`}>Роль пользователя</InputLabel>
                    <Select id={`role-${index}-select`} value={currentRow["role"]} label="Роль пользователя" onChange={(e) => { handleChangeRole(currentRow["id"], e) }}>
                        {roles.map((role) => {
                            return (<MenuItem value={role["id"]}>{role["name"]}</MenuItem>);
                        })}
                    </Select>
                </FormControl>
            </TableCell>
        </TableRow>
    );
}
