import React, { useState } from 'react';
import TableHeader from './SortTable/TableHeader';
import TableContent from './SortTable/TableContent';
import { Paper, Table, TableBody, TableContainer } from '@mui/material';
import _ from 'lodash';
import { GetComparator, StableSort } from '../../Utils/SortUtil';

export function RunDBTable(props) {
    const [orderDirection, setOrderDirection] = useState('asc');
    const [valueToOrderBy, setValueToOrderBy] = useState(_.keys(props.runListInDB[0])[0]);

    const handleRequestSort = (event, property) => {
        const isAscending = (valueToOrderBy === property && orderDirection === 'asc');
        setValueToOrderBy(property);
        setOrderDirection(isAscending ? 'desc' : 'asc')
    }

    const whiteColor = 'white';

    const getColor = (type) => {
        switch(type) {
            case 1: {
                return "#FFA500";
            }
            case 2: {
                return "#9ccc65";
            }
            default: {
                return whiteColor;
            }
        }
    }

    const { runListInDB } = props;
    return (
        <Paper sx={{ overflow: 'hidden' }}>
            <TableContainer sx={{ maxHeight: 440 }}>
                <Table stickyHeader aria-label="sticky table">
                    <TableHeader
                        valueToOrderBy={valueToOrderBy}
                        orderDirection={orderDirection}
                        handleRequestSort={handleRequestSort}
                        keys={_.take(_.keys(runListInDB[0]), 1)}
                        columnColl={['Run']}
                    />
                    <TableBody>
                        {
                            StableSort(runListInDB, GetComparator(orderDirection, valueToOrderBy))
                                .map(({ item1, item2 }, index) => {
                                    let bgColor = getColor(item2);
                                    return (
                                        <TableContent
                                            key={index}
                                            index={index}
                                            primaryColor={bgColor}
                                            secondaryColor={bgColor}
                                            items={[item1]}
                                            txtColor={bgColor === whiteColor ? "black" : whiteColor}
                                        />
                                    );
                                })
                        }
                    </TableBody>
                </Table>
            </TableContainer>
        </Paper>
    );
}