import { TableCell, TableRow } from '@mui/material';
import _ from 'lodash';
import React from 'react';
import DoneOutlineIcon from '@mui/icons-material/DoneOutline';
import AddIcon from '@mui/icons-material/Add';
import { styled } from '@mui/material/styles';

//background: linear-gradient(45deg, ${props => props.color} 25%, white 0, white 50%, ${props => props.color} 0, ${props => props.color} 75%, white 0);
//background-size: 140px 140px; }

const StyledTableRow = styled(({ primaryColor, secondaryColor, ...other }) => (<TableRow {...other} />))`
    background: linear-gradient(to right,  ${props => props.primaryColor} 50%, ${props => props.secondaryColor} 0);
    background-size: 120px 100%;
`

export default function TableContent({
    index, 
    primaryColor,
    secondaryColor,
    items, 
    txtColor, 
    handleClick, 
    currentRow, 
    selected,
    trueIcon = <DoneOutlineIcon/>,
    falseIcon = <AddIcon />,
    fontWeight = 400,
})
{
    const keyPart = items[index] || "empty";
    return (
        <StyledTableRow
            key={`row_${index}_${keyPart}`} 
            primaryColor={primaryColor}
            secondaryColor={secondaryColor}
            onClick={(event) => handleClick(event, currentRow)} 
            selected={selected}>
            {items.map((item, idx) =>
                {
                    if (_.isBoolean(item)) {
                        return (
                            <TableCell key={`cell_${index}_${idx}`} sx={{ color: txtColor, fontWeight: fontWeight }}> 
                                {item ? trueIcon : falseIcon} 
                            </TableCell>
                        );
                    }

                    return (
                        <TableCell key={`cell_${index}_${idx}`} sx={{ color: txtColor }}> 
                            {item} 
                        </TableCell>
                    );
                })}
        </StyledTableRow>
    );
}
