import React from 'react';
import Box from '@mui/material/Box';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import _ from 'lodash';

export function JobsList({
    setRuns,
    setSelectJob,
    setSelectRun,
    jobs,
    name,
    job
}) {
    const openListRuns = (job) => {
        console.log("job is", job);

        fetch(`/rj/GetRuns/?job=${job}`)
            .then((res) => {
                console.log("res is", res)
                return res.json();
            })
            .then((res) => {
                console.log("Runs upload", res);
                setRuns(res);
                setSelectJob(job);
                setSelectRun('');
            });
    };

    console.log('jobs', jobs);

    let items = _.isArray(jobs) ? jobs : [jobs];

    return (
        <Box sx={{
            width: '100%',
            height: '40vh',
        }}>
            <Typography variant="h6" component="div">
                {name}
            </Typography>
            <Paper elevation={1}>
                <List sx={{ overflow: 'auto', height: '35vh' }} >
                    {items.map((item, index) => {
                        const isSelected = item === job;
                        const bgColor = isSelected ? '#ffcc1c' : 'white';
                        const txtColor = 'black';
                        return (
                            <ListItemButton sx={{
                                background: bgColor, "&:hover":
                                {
                                    color: 'black',
                                    background: '#ffdd1d'
                                }
                            }} key={index} onClick={() => openListRuns(item)} >
                                <ListItemText sx={{ color: txtColor }}> {item} </ListItemText >
                            </ListItemButton>
                        );
                    })}
                </List>
            </Paper>
        </Box>
    );
}