import React, { useEffect, useState } from 'react';
import { Box, Accordion, AccordionSummary, Typography, AccordionDetails, Button } from '@mui/material';
import { LoadingWindow } from '../common/LoadingWindow';
import { AlertNotification } from '../common/AlertNotification';
import { ItemAccordionRun } from './ItemAccordionRun';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import _ from 'lodash';
import { HomeTableList } from './HomeTableList';

export function Home ({
    selectedJob,
    selectedRun,
    detailInfo,
    setDetailInfo,
    info,
    statuses,
    setIsRotary,
    isRotary,
    criticalStatuses,
    equipments,
    equimpentsToWriteView
}) {
    const [isOpen, setIsOpen] = useState(false);
    const [messageInfo, setMessageInfo] = useState("Error");
    const [alertState, setAlertState] = useState("error");
    const [isLoading, setIsLoading] = useState(false);
    const [loadingDesc, setLoadingDesc] = useState('Loading...');

    const handleSend = () => {
        setIsLoading(true);
        setLoadingDesc('Writing run details to db...');
        fetch(`/rj/WriteInfoRuns/?run=${selectedRun}&job=${selectedJob}`, {
            method: 'POST',
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({ detailInfo })
        })
            .then((res) => {
                if (res.ok) {
                    setAlertState("success");
                    setMessageInfo("Run details have been written.");
                }
                else {
                    setAlertState("error");
                    setMessageInfo("Failed to write run details.");
                }
                setIsLoading(false);
                setIsOpen(true);
                console.log("res is", res)
                return res.json();
            })
            .then((res) => {
                console.log(res);
            });
    }
    let jrString = '';
    if (selectedJob !== "" || selectedRun !== "") {
        jrString = selectedJob + '/' + selectedRun;
    }

    return (
        <div>
            <Box mb={0.5}>
                <Accordion expanded={selectedJob !== "" && selectedRun !== ""}>
                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                        <Typography sx={{ flexGrow: 1 }}>
                            Run Details
                        </Typography>
                        <Typography
                            sx={{
                                color: 'text.secondary',
                                flexGrow: 0
                            }}>
                            {jrString}
                        </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <ItemAccordionRun
                            detailInfo={detailInfo}
                            setDetailInfo={setDetailInfo}
                        />
                        <Button
                            color="error"
                            variant="outlined"
                            style={{ marginBottom: '1rem' }}
                            component="label"
                            onClick={handleSend}
                        >
                            Write details in DB
                        </Button>
                    </AccordionDetails>
                </Accordion>
            </Box>
            <HomeTableList
                selectedJob={selectedJob}
                selectedRun={selectedRun}
                info={info}
                statuses={statuses}
                setIsRotary={setIsRotary}
                isRotary={isRotary}
                criticalStatuses={criticalStatuses}
                equipments={equipments}
                equimpentsToWriteView={equimpentsToWriteView}
            />
            <AlertNotification
                alertState={alertState}
                messageInfo={messageInfo}
                setIsOpen={setIsOpen}
                isOpen={isOpen}
            />
            <LoadingWindow loadingDesc={loadingDesc} isLoading={isLoading} />
        </div>
    );
}

