import React from 'react';
import { Stack, Typography} from "@mui/material";


export default function Login() {

    const [isInit, setIsInit] = React.useState(true);
    const [oAuthLink, setOAuthLink] = React.useState('');

    const routeChange = () => {
        window.location.replace(oAuthLink);
    }

    React.useEffect(() => {
        if (isInit) {
            fetch(`whitelist/getOAuthLink`).then((res) => res.json()).then((res) => {
                setOAuthLink(res.link);
                setIsInit(false);
            })
        }
        if (oAuthLink != '')
        {
            routeChange();
        }
    });

    return (
            <Stack alignItems="center" justifyContent="space-around" width="100%" height="100%" marginTop='5rem'>
                <Typography variant='h4'>
                    Перенаправление на страницу авторизации...
                </Typography>
            </Stack>
    );
}
