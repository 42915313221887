import { Grid, TableCell, TableHead, TableRow, TableSortLabel } from '@mui/material';
import React, { useState } from 'react';

export default function TableHeader({
    valueToOrderBy, 
    orderDirection, 
    handleRequestSort, 
    keys, 
    columnColl,
    widthCols = []
})
{
    const [isHover, setIsHover] = useState(false);

    const handleMouseEnter = () => {
        setIsHover(true);
    };
    const handleMouseLeave = () => {
        setIsHover(false);
    };

    const wrapperStyle =  
    {
        cursor: isHover ? 'pointer': 'default'
    }

    return (
        <TableHead>
            <TableRow>
                {keys.map((item, index) => {
                    const width = widthCols[index];
                    if (width) {
                        wrapperStyle.width = width;
                    }
                    
                    return (
                        <TableCell 
                            key={item} 
                            onClick={(event) => { handleRequestSort(event, item) }}
                            onMouseLeave={handleMouseLeave}
                            onMouseEnter={handleMouseEnter}
                            style={wrapperStyle}>
                                <Grid container rowSpacing={1}>
                                    <Grid>
                                        <TableSortLabel
                                            key={item}
                                            active={valueToOrderBy === item}
                                            direction={valueToOrderBy === item ? orderDirection : 'asc'}
                                        />
                                    </Grid>
                                    <Grid>
                                        {columnColl[index]}   
                                    </Grid>                        
                                </Grid>
                        </TableCell>
                    );
                })}
            </TableRow>
        </TableHead>
    );
}
