import React, { useState, useEffect } from 'react';
import { Container } from 'reactstrap';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import FormGroup from '@mui/material/FormGroup';
import Button from '@mui/material/Button';

import { JobsList } from './JobsList'
import { RunsList } from './RunsList'

export function NavItem({
    jobs,
    runs,
    setJobs,
    setRuns,
    setSelectJob,
    setSelectRun,
    job, 
    run,
    handleOpen
}) {
    const [searchQuery, setSearchQuery] = useState("");
    const openJobsList = () => {

        fetch(`/rj/GetJobs/${searchQuery}`)
            .then((res) => {
                console.log("res is", res)
                return res.json();
            })
            .then((res) => {
                setJobs(res);
                setRuns([]);
                setSelectRun('');
            });
    };

    const handleChange = event => {
        setSearchQuery(event.target.value);
    };

    const handleKeyDown = (event) => {
        
        if (event.key === 'Enter')
        {
            event.preventDefault();
            openJobsList();
        }
    }

    return (
        <Container fluid>
            <Box component="form">
                <TextField
                    id="search jobs"
                    size="small"
                    fullWidth
                    margin="normal"
                    label="search jobs"
                    onChange={handleChange}
                    onKeyDown={(e) => { handleKeyDown(e) }}
                />
                <FormGroup>
                    <Button variant="outlined" onClick={openJobsList}>Search</Button>
                </FormGroup>
                <Box>
                    <JobsList
                        name="Jobs"
                        jobs={jobs}
                        setRuns={setRuns}
                        setSelectJob={setSelectJob}
                        setSelectRun={setSelectRun}
                        job={job}
                    />
                    <RunsList
                        name="Runs"
                        item={runs}
                        setSelectRun={setSelectRun}
                        run={run}
                        handleOpen={handleOpen}
                        job={job}
                    />
                </Box>
            </Box>
        </Container>
    );
}


