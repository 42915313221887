import React, { Component, useState } from 'react';
import { Input } from 'reactstrap';
import { ButtonExport } from './ButtonExport';
import { AlertNotification } from '../common/AlertNotification';
import { LoadingWindow } from '../common/LoadingWindow';
import {
    Button, Box, Stack, Typography, FormControl,
    InputLabel, Select, MenuItem, Dialog, DialogTitle,
    DialogContent, DialogContentText, DialogActions
} from '@mui/material';
import { Accordion, AccordionDetails, AccordionSummary} from '@mui/material';
import { CBMEquipmentTable } from './CBMEquipmentTable';
import { CBMGeneralTable } from './CBMGeneralTable';
import { CBMViewTable } from './CBMViewTable';
import { GetComparator, StableSort } from '../Utils/SortUtil';
import _, { cloneWith, toLower, trimEnd } from 'lodash';
import { ButtonImport } from './ButtonImport';
import { OneCFileHistoryTable } from './OneCFileHistoryTable';
import { InsiteFileHistoryTable } from './InsiteFileHistoryTable';
import ComboBox from './ComboBox';
import { LightTooltip } from './../Utils/LightTooltip';

const FilterSelect = function FilterSelect(props)
{
    return (
        <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
            <InputLabel id={props.label}>{props.name}</InputLabel>
            <Select
                labelId={props.label}
                value={props.value}
                onChange={props.handleChange}
                label={props.label}
                disabled={props.disabled}
            >
                <MenuItem value="">
                    <em>None</em>
                </MenuItem>
                {props.items.map((item, idx) => { 
                    return (
                        <MenuItem key={idx} value={item}>{item}</MenuItem>
                    );
                })}
            </Select>
        </FormControl>
    );
}

function TableAccordion({
    name,
    children,
    bgColor = "#d32f2f",
    txtColor = "white"
}) {
    return (
        <Accordion>
            <AccordionSummary sx={{ background: bgColor }}>
                <Typography sx={{ color: txtColor }}>
                    {name}
                </Typography>
            </AccordionSummary>
            <AccordionDetails>{children}</AccordionDetails>           
        </Accordion>
    );
}

const TableWrapper = (function TableWrapper(props) {
    return (
        <Box mb={0.5} {...props} />
    );
});

export function ImportFrom1C(props) {
    function ButtonHelper ({
        jobs,
        runs,
        equipments
    }) {
        return (
            <Stack direction="row" spacing={2} sx={{ marginLeft: '1rem' }}>
                <LightTooltip title="Add hours to selected equipment in 1C">
                    <Button
                        color="error"
                        variant="outlined"
                        style={{ marginBottom: '1rem' }}
                        component="label"
                        onClick={() => {
                            handleSelectedSubmit('Update');
                            handleLoad();
                            handleClear();
                        }}
                    >
                        Update 1C ERP
                    </Button>
                </LightTooltip>
                <LightTooltip title="Delete selected equipment and remove hours from 1C">
                    <Button
                        color="error"
                        variant="outlined"
                        style={{ marginBottom: '1rem' }}
                        component="label"
                        onClick={() => {
                            handleSelectedSubmit('Rollback');
                            handleLoad();
                            handleClear();
                        }}
                    >
                        Delete 1C ERP and MDB
                    </Button>
                </LightTooltip>
                <ComboBox
                    label='Job'
                    values={jobs}
                    handleChange={handleChangeJob}
                    value={job}
                    width={'15vw'}
                />
                <ComboBox
                    label='Run'
                    values={runs}
                    handleChange={handleChangeRun}
                    value={run}
                    width={'10vw'}
                />
                <ComboBox
                    label='Equipment'
                    values={equipments}
                    handleChange={setEquipment}
                    value={equipment}
                    width={'10vw'}
                />
                <Button
                    color="error"
                    variant="outlined"
                    style={{ marginBottom: '1rem' }}
                    component="label"
                    onClick={handleSelect}
                >
                    Select rows with parameters
                </Button>
                <Button
                    color="error"
                    variant="outlined"
                    style={{ marginBottom: '1rem' }}
                    component="label"
                    onClick={handleClear}
                >
                    Clear selected rows
                </Button>
            </Stack>
        );
    }

    const [job, setJob] = useState('');
    const [run, setRun] = useState('');
    const [equipment, setEquipment] = useState('');

    const handleChangeJob = (value) => {
        setJob(value);
        setRun("");
    };

    const handleChangeRun = (value) => {
        setRun(value);
    };

    const [loadingDescription, setLoadingDescription] = useState();
    const [isOpen, setIsOpen] = useState(false);
    const [equipmentsCBM, setEquipmentsCBM] = useState([]);
    const [filteredCBM, setFilteredCBM] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [messageInfo, setMessageInfo] = useState("File not found"); //if fetch doesn't work
    const [alertState, setAlertState] = useState("error");
    const [selectedRows, setSelectedRows] = React.useState([]);
    const [scrollValue, setScrollValue] = useState(0);
    const [isReloadFileHistory, setIsReloadFileHistory] = useState(false);
    const [fileName, setFileName] = useState(null);
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(5);
    const [orderDirection, setOrderDirection] = useState('asc');
    const [valueToOrderBy, setValueToOrderBy] = useState();
    const [isIgnoreDialogOpen, setIsIgnoreDialogOpen] = useState(false);
    const [ignoreDialogText, setIgnoreDialogText] = useState('');
    const [forceDialogText, setForceDialogText] = useState('');
    const [loopedDialogText, setLoopedDialogText] = useState('');
    const [generalPage, setGeneralPage] = React.useState(0);
    const [generalRowsPerPage, setGeneralRowsPerPage] = React.useState(5);
    const [generalOrderDirection, setGeneralOrderDirection] = useState('asc');
    const [generalValueToOrderBy, setGeneralValueToOrderBy] = useState();

    const handleLoad = () =>
    {
        fetchCbm();
        setIsReloadFileHistory(false);
    }

    const fetchCbm = (file = {fileName: '', createdDate: ''}) => {        
        setIsLoading(true); 
        setLoadingDescription("Loading CBM table");

        const requestOptions =
        {
            method: "POST",
            headers:
            {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({key: file.fileName, value: file.createdDate}),
        }; 

        fetch("/erp/GetCbm", requestOptions)
            .then((res) =>
            {
                return res.json();
            })
            .then((res) =>
            {
                console.log("get check json", res);
                if (res && _.isArray(res)) {
                    const items = _.map(res, v => {
                        let item = v.equipment;
                        item.lastFileName = v.lastFileName || "";
                        item.isAlreadyWritten = v.isAlreadyWritten || false;
                        return item;
                    })
                    setEquipmentsCBM(items);
                    setIsLoading(false);
                }
            });
    }

    const handleClose = () =>
    {
        setIsIgnoreDialogOpen(false);
        setForceDialogText("");
        setIgnoreDialogText("");
        setLoopedDialogText("");
    }

    const changeStateRows = (filterComparer) =>
    {
        setSelectedRows([...selectedRows, ..._.differenceBy(_.filter(equipmentsCBM, (row) => filterComparer(row)), selectedRows, 'id')]);
    }

    const overwriteStateRows = (filterComparer) => {
        setSelectedRows(_.filter(equipmentsCBM, (row) => filterComparer(row)));
    }

    const handleSelect = () => {
        const rows = StableSort(equipmentsCBM, GetComparator(orderDirection, valueToOrderBy));
        const CBMWithoutEquipment = _.uniqWith(rows.map((row) => {
            return ({ isAlreadyWritten: row.isAlreadyWritten, job: row.job, run: row.run, ref_date: row.ref_date });
        }), (rowA, rowB) => {
            return (rowA.job === rowB.job && rowA.run == rowB.run);
        });
        let firstEntryIndex = 0;
        if (job !== "") {
            if (run !== "") {
                firstEntryIndex = CBMWithoutEquipment.indexOf(CBMWithoutEquipment.filter((row) => row.job === job && row.run === run)[0]);
                if (equipment != "") {
                    overwriteStateRows((row) => row.equipment === equipment && row.job === job && row.run === run);
                } else {
                    changeStateRows((row) => row.job === job && row.run === run);
                }
            }
            else {
                firstEntryIndex = CBMWithoutEquipment.indexOf(CBMWithoutEquipment.filter((row) => row.job === job)[0]);
                changeStateRows((row) => row.job === job);
            }
            const tempPage = Math.floor(firstEntryIndex / rowsPerPage);
            setGeneralPage(tempPage);
            setFilteredCBM([]);
        } else
        {
            if (equipment != "") {
                overwriteStateRows((row) => row.equipment === equipment);
            }
        }
    }

    const handleClear = () =>
    {
        setSelectedRows([]);
    }

    const handleSelectedSubmit = (action) =>
    {
        setIsLoading(true);
        setLoadingDescription("Submitting...");

        const requestOptions =
        {
            method: "POST",
            headers:
            {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(selectedRows),
        };

        fetch(`/erp/${action}1CERP`, requestOptions)
            .then((res) => {
                setIsLoading(false);
                setIsOpen(true);
                console.log("file upload", res);
                if (res.ok) {
                    setAlertState("success");
                    setMessageInfo(`Successful ${toLower(action)}`);
                    setIsReloadFileHistory(true);
                    return res.json();
                }
                else {
                    setAlertState("error");
                    const error = res.json();
                    error.then((ex) => {
                        setMessageInfo(`Failed to perform ${toLower(action)}: ${ex.error}`);
                    });
                }
            });
    }

    const jobs = _.uniqBy(equipmentsCBM, 'job')
                    .map(item => item.job);

    const runsByJob = _.filter(equipmentsCBM, (row) => row.job === job);
    const runs = _.uniqBy(runsByJob, 'run').map(item => item.run);

    const getEquipments = () =>
    {
        let tempEquipment = structuredClone(equipmentsCBM)
        if (job != '') {
            if (run != '') {
                tempEquipment = tempEquipment.filter(item => item.job === job && item.run === run);
            } else {
                tempEquipment = tempEquipment.filter(item => item.job === job && item.run === run);
            }
        } 
        return tempEquipment;
    }

    const equipments = ['', ..._.uniq(getEquipments().map((row) => row.equipment))];

    return (
        <div style={{ height: '100%' }}>
            <Stack direction="column" spacing={2}>
                <Typography variant="h7">
                    Current File: {fileName || "No file"}
                </Typography>
                <Stack direction="row" spacing={2}>
                    <ButtonImport 
                        setIsReloadFileHistory={setIsReloadFileHistory}
                        setIsLoading={setIsLoading}
                        setLoadingDescription={setLoadingDescription}
                        setIsOpen={setIsOpen}
                        setAlertState={setAlertState}
                        setMessageInfo={setMessageInfo}
                        setIsIgnoreDialogOpen={setIsIgnoreDialogOpen}
                        setIgnoreDialogText={setIgnoreDialogText}
                        setFileName={setFileName}
                        setForceIgnoreText={setForceDialogText}
                        setLoopedDialogText={setLoopedDialogText}
                    />
                    <ButtonExport 
                        setIsLoading={setIsLoading}
                        setLoadingDescription={setLoadingDescription}
                        setIsOpen={setIsOpen}
                        setAlertState={setAlertState}
                        setMessageInfo={setMessageInfo}/>
                </Stack>
            </Stack>
            <InsiteFileHistory changes={isReloadFileHistory}/>
            <OneCFileHistory changes={isReloadFileHistory} fetchCbm={fetchCbm} handleLoad={handleLoad}/>
            <CbmHistory 
                equipmentsCBM={equipmentsCBM}
                selectedRows={selectedRows}
                setSelectedRows={setSelectedRows}
                filteredCBM={filteredCBM}
                setFilteredCBM={setFilteredCBM}
                scrollValue={scrollValue}
                setScrollValue={setScrollValue}
                jobs={jobs}
                runs={runs}
                ButtonHelper={ButtonHelper}
                page={page}
                setPage={setPage}
                rowsPerPage={rowsPerPage}
                setRowsPerPage={setRowsPerPage}
                valueToOrderBy={valueToOrderBy}
                setValueToOrderBy={setValueToOrderBy}
                orderDirection={orderDirection}
                setOrderDirection={setOrderDirection}
                generalPage={generalPage}
                setGeneralPage={setGeneralPage}
                generalRowsPerPage={generalRowsPerPage}
                setGeneralRowsPerPage={setGeneralRowsPerPage}
                generalValueToOrderBy={generalValueToOrderBy}
                setGeneralValueToOrderBy={setGeneralValueToOrderBy}
                generalOrderDirection={generalOrderDirection}
                setGeneralOrderDirection={setGeneralOrderDirection}
                equipments={equipments}
            />
            <Dialog
                open={isIgnoreDialogOpen}
                onClose={handleClose}
                aria-labelledby="ignore-notification-title"
                aria-describedby="ignore-notification-description">
                <DialogTitle id="ignore-notification-title">
                    Warning!
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="ignore-notification-description">
                        <Typography>{ignoreDialogText !== '' ? `Next SNs have been ignored due to invalidity: ${ignoreDialogText.trimEnd()}.` : ''}</Typography>
                        <Typography>{loopedDialogText !== '' ? loopedDialogText : ''}</Typography>
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} key={"dialog-button-ok"}>
                        OK
                    </Button>
                </DialogActions>
            </Dialog>
            <AlertNotification
                alertState={alertState}
                messageInfo={messageInfo}
                setIsOpen={setIsOpen.bind(this)}
                isOpen={isOpen}
            />
            <LoadingWindow isLoading={isLoading} loadDesc={loadingDescription} />
        </div>
    );
}

const OneCFileHistory = ({
    fetchCbm,
    handleLoad,
    changes
}) => {
    return (
        <TableWrapper>
            <TableAccordion name='Uploaded 1C files'>
                <OneCFileHistoryTable
                    changes={changes}
                    handleLoad={handleLoad}
                    setSelected={(file) => {
                        fetchCbm(file);
                    }}/>
            </TableAccordion>
        </TableWrapper>
    );
}

const InsiteFileHistory = ({
    changes
}) => {
    return (
        <TableWrapper>
            <TableAccordion name='Uploaded InSite files'>
                <InsiteFileHistoryTable changes={changes}/>
            </TableAccordion>
        </TableWrapper>
    );
}

const CbmHistory = ({
    jobs,
    runs,
    equipmentsCBM,
    selectedRows,
    setSelectedRows,
    scrollValue,
    setScrollValue,
    ButtonHelper,
    page,
    setPage,
    rowsPerPage,
    setRowsPerPage,
    valueToOrderBy,
    setValueToOrderBy,
    orderDirection,
    setOrderDirection,
    generalPage,
    setGeneralPage,
    generalRowsPerPage,
    setGeneralRowsPerPage,
    generalValueToOrderBy,
    setGeneralValueToOrderBy,
    generalOrderDirection,
    setGeneralOrderDirection,
    filteredCBM,
    setFilteredCBM,
    equipments
}) => {
    return (
        <TableWrapper>
            <TableAccordion name='CBM table from DB'>
                <Typography>
                    Job-run CBM table
                </Typography>
                <CBMGeneralTable
                    CBM={equipmentsCBM}
                    setFilteredCBM={setFilteredCBM}
                    filteredCBM={filteredCBM}
                    selected={selectedRows}
                    scrollValue={scrollValue}
                    setScrollValue={setScrollValue}
                    page={generalPage}
                    setPage={setGeneralPage}
                    rowsPerPage={generalRowsPerPage}
                    setRowsPerPage={setGeneralRowsPerPage}
                    valueToOrderBy={generalValueToOrderBy}
                    setValueToOrderBy={setGeneralValueToOrderBy}
                    orderDirection={generalOrderDirection}
                    setOrderDirection={setGeneralOrderDirection}
                />
                <Typography>
                    Job-run CBM details
                </Typography>
                <CBMEquipmentTable
                    CBM={filteredCBM}
                    selected={selectedRows}
                    setSelected={setSelectedRows}
                    scrollValue={scrollValue}
                    setScrollValue={setScrollValue}
                    page={page}
                    setPage={setPage}
                    rowsPerPage={rowsPerPage}
                    setRowsPerPage={setRowsPerPage}
                    valueToOrderBy={valueToOrderBy}
                    setValueToOrderBy={setValueToOrderBy}
                    orderDirection={orderDirection}
                    setOrderDirection={setOrderDirection}
                />
                <Typography>
                    Selected equipments
                </Typography>
                <CBMViewTable
                    CBM={selectedRows}
                />
                <ButtonHelper jobs={jobs} runs={runs} equipments={equipments} />
            </TableAccordion>
        </TableWrapper>
    );
}