import * as React from 'react';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import { NavItem } from './NavItem';

export default function NavDrawer({
    jobs,
    runs,
    setJobs,
    setRuns,
    setSelectJob,
    setSelectRun,
    job,
    run,
    open,
    handleDrawerClose,
    drawerWidth,
    handleOpen
}) {

    return (
        <div>
            <Drawer
                anchor="left"
                open={open}
                variant="persistent"
                onClose={handleDrawerClose}
                sx={{
                    width: open ? drawerWidth : 100,
                    flexShrink: 0,
                    '& .MuiDrawer-paper': {
                        width: drawerWidth,
                        boxSizing: 'border-box',
                    },
                }}
            >
            
                <IconButton onClick={handleDrawerClose}>
                    <ChevronLeftIcon />
                </IconButton>
            
                <NavItem
                    jobs={jobs}
                    runs={runs}
                    setJobs={setJobs}
                    setRuns={setRuns}
                    setSelectJob={setSelectJob}
                    setSelectRun={setSelectRun}
                    job={job}
                    run={run}
                    handleOpen={handleOpen}
                />
            </Drawer>
        </div>
    );
}