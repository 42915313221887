import React from 'react';
import Box from '@mui/material/Box';
import { TableInfo } from './TableInfo'
import { TableEquipment } from './TableEquipment';
import { TableStatuses } from './TableStatuses';
import { RunDBTable } from './RunDBTable';
import { EquipmentCmbWrapper } from './EquipmentCbmTable';
import { Accordion, AccordionDetails, AccordionSummary, Typography } from '@mui/material';
import _ from 'lodash';
import { TableEquipmentDuplicates } from './TableEqDuplicates';

const TableWrapper = (function TableWrapper(props) {
    return (
        <Box mb={0.5} {...props} />
    );
});

const TableAccordion = (function TableAccordion(props)
{
    return (
        <Accordion>
            <AccordionSummary sx={{ background: props.bgColor }}>
                <Typography sx={{ color: props.txtColor }}>
                    {props.name}
                </Typography>
            </AccordionSummary>
            <AccordionDetails>{props.children}</AccordionDetails>
        </Accordion>
    );
})

export function TableList(props) {
    const bgColor = "#d32f2f";
    const txtColor = "white";

    return (
        <Box sx={{ width: '100%', height: '100vh' }}>
            <TableWrapper>
                <TableAccordion
                    bgColor={bgColor}
                    txtColor={txtColor}
                    name='Info'>
                        <TableInfo info={props.info} />
                </TableAccordion>
            </TableWrapper>
            <TableWrapper>
                <TableAccordion
                    bgColor={bgColor}
                    txtColor={txtColor} 
                    name='Run list in DB'>
                        <RunDBTable runListInDB={props.runListInDB} />
                </TableAccordion>
            </TableWrapper>
            <TableWrapper>
                <TableAccordion
                    bgColor={bgColor}
                    txtColor={txtColor}
                    name='Statuses'>
                    <TableStatuses statuses={props.statuses} setWarning={props.setWarning} warningState={props.warningState}
                        disabledWrite={props.disabledWrite} setDisabled={props.setDisabled} criticalWarningText={props.criticalWarningText}
                        setCriticalWarningText={props.setCriticalWarningText} setIsRotary={props.setIsRotary}
                        setIsTimesExist={props.setIsTimesExist} isTimesExist={props.isTimesExist}
                        isRotary={props.isRotary} isCheckerExists={props.isCheckerExists} setStatuses={props.setStatuses} />
                </TableAccordion>
            </TableWrapper>
            <TableWrapper>
                <TableAccordion
                    bgColor={bgColor}
                    txtColor={txtColor}
                    name='Critical statuses'>
                    <TableStatuses statuses={props.criticalStatuses} setWarning={props.setWarning} warningState={props.warningState}
                        disabledWrite={props.disabledWrite} setDisabled={props.setDisabled} criticalWarningText={props.criticalWarningText}
                        setCriticalWarningText={props.setCriticalWarningText} setIsRotary={props.setIsRotary}
                        setIsTimesExist={props.setIsTimesExist} isTimesExist={props.isTimesExist}
                        isRotary={props.isRotary} isCheckerExists={props.isCheckerExists} setStatuses={props.setCriticalStatuses} />
                </TableAccordion>
            </TableWrapper>
            <TableWrapper>
                <TableAccordion
                    bgColor={bgColor}
                    txtColor={txtColor}
                    name='Equipment'>
                        <TableEquipment equipments={_.uniqBy(props.equipments, 'item1')} />
                </TableAccordion>
            </TableWrapper>
            <TableWrapper>
                <TableAccordion
                    bgColor={bgColor}
                    txtColor={txtColor}
                    name='Equipment to write'>
                        <EquipmentCmbWrapper equimpentsToWriteView={props.equimpentsToWriteView} />
                </TableAccordion>
            </TableWrapper>
            <TableWrapper>
                <TableAccordion
                    bgColor={bgColor}
                    txtColor={txtColor}
                    name='Equipment duplicates'>
                    <TableEquipmentDuplicates dups={props.dups} />
                </TableAccordion>
            </TableWrapper>
       </Box>
    );
}

