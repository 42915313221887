import React, { useState, useEffect } from 'react';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import TableHeader from '../merge/Tables/SortTable/TableHeader';
import _ from 'lodash';
import BlockIcon from '@mui/icons-material/Block';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import { TableCell, TableRow, Typography } from '@mui/material';
import { ToDateFormat } from '../Utils/DateUtil';

function getOneCFileHistory(handleLoad, setData, setLastFile) {
    fetch(`/erp/getOneCFileHistory`)
        .then((res) => res.json())
        .then((oneCFiles) => {
            const lastFile = _.last(_.orderBy(oneCFiles, v => v.createdDate, 'asc')) || {};
            handleLoad();
            setData(oneCFiles);
            setLastFile(lastFile);
        });
}

export function OneCFileHistoryTable({
    setSelected, changes, handleLoad
}) {
    const [data, setData] = useState([]);
    const [lastFile, setLastFile] = useState({});

    useEffect(() => {
        getOneCFileHistory(handleLoad, setData, setLastFile);
    }, [changes]);

    return (
        <OneCFileHistoryTableInner lastFile={lastFile} data={data} setSelected={setSelected}/>
    );
}

function Rows({data, lastFile, selectedRow, handleClick}) {
    const rows = _.map(data, ({ fileName, createdDate }, index) =>
    {
        const currentRow = {
            fileName, createdDate
        };

        let isRowSelected = false;
        if (selectedRow 
                && fileName === selectedRow.fileName 
                && createdDate === selectedRow.createdDate)
        {
            isRowSelected = true;
        }

        let bgColor = null;
        let color = 'black';
        if (lastFile 
                && lastFile.fileName === fileName 
                && lastFile.createdDate === createdDate) {
            bgColor = "#adb5bd";
            color = isRowSelected ? 'black' : 'white';
        }

        const date = ToDateFormat(createdDate);

        return (
            <TableRow 
                key={`row_${index}`} 
                sx={{ background: bgColor }} 
                onClick={(event) => handleClick(event, currentRow)} 
                selected={isRowSelected}>

                <TableCell key={`cell_${index}_fileName`} sx={{ color: color }}> 
                    {fileName}
                </TableCell>
                <TableCell key={`cell_${index}_createdDate`} sx={{ color: color }}> 
                    {date}
                </TableCell>
            </TableRow>
        );
    });
    return rows;
}

function OneCFileHistoryTableInner({data, setSelected, lastFile}) {
    const [orderDirection, setOrderDirection] = useState('desc');
    const [valueToOrderBy, setValueToOrderBy] = useState('createdDate');
    const [selectedRow, setSelectedRow] = useState(lastFile);

    const handleClick = (event, row) => {
        setSelectedRow(row);
        setSelected(row);
    };

    const handleRequestSort = (event, property) => {
        const isAscending = (valueToOrderBy === property && orderDirection === 'asc');
        setValueToOrderBy(property);
        setOrderDirection(isAscending ? 'desc' : 'asc')
    }

    const values = _.orderBy(data, [valueToOrderBy], [orderDirection]);

    const keys = ["fileName", "createdDate"];
    const cols = ['File Name', 'Upload Date'];
    const widths = ['300px', '300px', "10px", "10px"];

    return (
        <Paper sx={{ overflow: 'hidden' }}>
            <TableContainer sx={{ maxHeight: 440 }} id='tb-container'>
                <Table stickyHeader aria-label="sticky table">
                    <TableHeader
                        valueToOrderBy={valueToOrderBy}
                        orderDirection={orderDirection}
                        handleRequestSort={handleRequestSort}
                        keys={keys}
                        columnColl={cols}
                        widthCols={widths}
                    />
                    <TableBody>
                        <Rows data={values} lastFile={lastFile} selectedRow={selectedRow} handleClick={handleClick}/>
                    </TableBody>
                </Table>
            </TableContainer>
        </Paper>
    );
}
